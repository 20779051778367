import axios from 'axios';
import Vue from 'vue';
import * as general_mutations from '../mutations';
import * as general_state from '../store';
import router from '../../router/router'
import sections_list from './sections_list';

const state = {

	// Subsections data and structures for section
	subsections_data: {},

	// Subsections relation info table data
	subsections_relation_info_table_data: {},

	// Section level (1., 2. or 3.)
	section_level: '1',

	// Active subsection for section
	active_subsection_id: null,

	// workplantemporary
	staticHTMLTableStringSubsection: ""
};


const getters = {

	// Return all subsections data
	subsections_data: store => {
		return state.subsections_data;
	},

	// Return all subsections relation info table data
	subsections_relation_info_table_data: store => {
		return state.subsections_relation_info_table_data;
	},

	// Return data for specific subsection
	subsection_data: store => {
		let level = state.section_level;
		let subsection_id = state.active_subsection_id;

		if (state.subsections_data[level] == null || state.subsections_data[level][subsection_id] == null)
			return {};

		return state.subsections_data[level][subsection_id];
	},

	// Return current section level
	section_level: store => {
		return state.section_level;
	},

	// Return active subsection for section
	active_subsection_id: store => {
		return state.active_subsection_id;
	},

	// workplantemporary 
	staticHTMLTableStringSubsection: store => {
		return state.staticHTMLTableStringSubsection;
	}
};


const mutations = {

	// Set section level and set first tab as active
	setLevel: (state, payload) => {
		state.section_level = payload;

		//If subsections exist in this level
		if (state.subsections_data[state.section_level]) {
			for (var subsection_order in state.subsections_data[state.section_level]) {
				var subsection_id = state.subsections_data[state.section_level][subsection_order].id;
				break;
			}

			state.active_subsection_id = subsection_id;
		}
		//subsections does not exist in this level, reset active subsection
		else
			state.active_subsection_id = null;
	},

	// Change section statistics
	editSectionStatistics: (state, payload) => {
		let section_id = payload.section_id;
		let cnt_mand = payload.stat_count_mandatory;
		let cnt_mand_solv = payload.stat_count_solved_mandatory;

		sections_list.state.sections_statistics[section_id].stat_count_mandatory = cnt_mand;
		sections_list.state.sections_statistics[section_id].stat_count_solved_mandatory = cnt_mand_solv;
		sections_list.state.sections_statistics[section_id].stat_percantage = Math.floor(cnt_mand_solv / cnt_mand * 100);

		if (payload.stat_count_solved_all)
			sections_list.state.sections_statistics[section_id].stat_count_solved_all = payload.stat_count_solved_all;
		if (payload.stat_count_all)
			sections_list.state.sections_statistics[section_id].stat_count_all = payload.stat_count_all;
	},

	// Change section statistics simple
	editSectionStatisticsSimple: (state, payload) => {
		let section_id = payload.section_id;

		if (payload.mandatory == 1) {
			let cnt_mand = sections_list.state.sections_statistics[section_id].stat_count_mandatory;
			let cnt_mand_solv = sections_list.state.sections_statistics[section_id].stat_count_solved_mandatory += payload.edit;

			sections_list.state.sections_statistics[section_id].stat_count_solved_mandatory = cnt_mand_solv;
			sections_list.state.sections_statistics[section_id].stat_percantage = Math.floor(cnt_mand_solv / cnt_mand * 100);
		}

		sections_list.state.sections_statistics[section_id].stat_count_solved_all += payload.edit;
	},

	// Change section statistics simple for adding row
	editSectionStatisticsSimpleAddRow: (state, payload) => {
		let section_id = payload.section_id;
		let cnt_mand = sections_list.state.sections_statistics[section_id].stat_count_mandatory += payload.mandatory_columns;
		let cnt_mand_solv = sections_list.state.sections_statistics[section_id].stat_count_solved_mandatory;

		sections_list.state.sections_statistics[section_id].stat_count_mandatory = cnt_mand;
		sections_list.state.sections_statistics[section_id].stat_percantage = Math.floor(cnt_mand_solv / cnt_mand * 100);

		sections_list.state.sections_statistics[section_id].stat_count_all += payload.all_columns;
	},

	// Set active subsection
	setActiveSubsection: (state, payload) => {
		state.active_subsection_id = payload;
	},

	// Save subsections data to store
	prepareSubsectionsData: (state, payload) => {
		state.subsections_data = payload.data;

		// Set first subsection as active
		let level = state.section_level;

		//normal section
		for (var subsection_order in state.subsections_data[level]) {
			var subsection_id = state.subsections_data[level][subsection_order].id;
			break;
		}

		//united measures section
		if (subsection_id === undefined)
			var subsection_id = Object.keys(state.subsections_data)[0];

		state.active_subsection_id = subsection_id;
	},

	prepareSubsectionsRelationInfoTableData: (state, payload) => {
		state.subsections_relation_info_table_data = payload.data;
	},

	// Add new section level to array
	addSectionPage: (state, payload) => {
		let new_level = Object.keys(state.subsections_data.levels).length+1;

		//set level in level list object
		Vue.set(state.subsections_data.levels, 'level'+new_level, {});
		Vue.set(state.subsections_data.levels['level'+new_level], 'name', payload.name);
		Vue.set(state.subsections_data.levels['level'+new_level], 'enabled', "1");
		Vue.set(state.subsections_data.levels['level'+new_level], 'level', new_level.toString());

		//set level in level data object
		Vue.set(state.subsections_data, new_level.toString(), {});

		// Set last subsection as active
		state.section_level = new_level.toString();
		//reset subsection
		state.active_subsection_id = null;
	},

	// Edit section level to array
	editSectionPage: (state, payload) => {
		let level = state.section_level;
		Vue.set(state.subsections_data.levels['level'+level], 'name', payload.name);
	},

	// Delete section level 
	deleteSectionPage: (state, payload) => {
		state.subsections_data = payload.data.data;

		// Force refresh - VUE SET...?
		var temp = state.subsections_data;
		state.subsections_data = {};
		state.subsections_data = temp;

		//set first visible level
		for(let levelkey in state.subsections_data.levels){
			if(state.subsections_data.levels[levelkey].enabled == "1"){
				state.section_level = state.subsections_data.levels[levelkey].level;
				break;
			}
		}

		let subsection_id = null;
		// Set first subsection as active      
		for (var subsection_order in state.subsections_data[state.section_level]) {
			subsection_id = state.subsections_data[state.section_level][subsection_order].id;
			break;
		}

		state.active_subsection_id = subsection_id;
	},

	// Add new subsection to array
	addSubsection: (state, payload) => {
		state.subsections_data = payload.data;

		// Set last subsection as active
		let level = state.section_level;

		for (var subsection_order in state.subsections_data[level]) {
			var subsection_id = state.subsections_data[level][subsection_order].id;
		}

		state.active_subsection_id = subsection_id;
	},

	// Edit subsection in array
	editSubsection: (state, payload) => {
		let level = state.section_level;
		let active_subsection_id = state.active_subsection_id;

		state.subsections_data[level][active_subsection_id].name = payload.name;
		state.subsections_data[level][active_subsection_id].description = payload.description;
		state.subsections_data[level][active_subsection_id].predefined = payload.predefined;
		state.subsections_data[level][active_subsection_id].united_measures = payload.united_measures;
	},

	// Remove subsection from array
	deleteSubsection: (state) => {
		let level = state.section_level;
		let active_subsection_id = state.active_subsection_id;

		delete state.subsections_data[level][active_subsection_id];

		// Set first subsection as active      
		for (var subsection_order in state.subsections_data[level]) {
			var subsection_id = state.subsections_data[level][subsection_order].id;
			break;
		}

		state.active_subsection_id = subsection_id;
	},

	// Refresh Subesctions data
	refreshSubsectionsData: (state, payload) => {
		state.subsections_data = payload.data;
	},

	// Add new column in info table
	addSubsectionInfoTableColumn: (state, payload) => {
		let level = state.section_level;
		let active_subsection_id = state.active_subsection_id;

		state.subsections_data[level][active_subsection_id]['info_table'] = payload.data;
	},

	// Edit column
	editSubsectionTableColumn: (state, payload) => {
		let level = state.section_level;
		let active_subsection_id = state.active_subsection_id;

		state.subsections_data[level][active_subsection_id]['columns'][payload.order_number]['name'] = payload.name;
		state.subsections_data[level][active_subsection_id]['columns'][payload.order_number]['description'] = payload.description;
		state.subsections_data[level][active_subsection_id]['columns'][payload.order_number]['mandatory'] = payload.mandatory;
		state.subsections_data[level][active_subsection_id]['columns'][payload.order_number]['highlighted'] = payload.highlighted;
		state.subsections_data[level][active_subsection_id]['columns'][payload.order_number]['personal_data'] = payload.personal_data;

		if (payload.type)
			state.subsections_data[level][active_subsection_id]['columns'][payload.order_number]['type'] = payload.type;
		if (payload.disabled)
			state.subsections_data[level][active_subsection_id]['columns'][payload.order_number]['disabled'] = payload.disabled;
	},

	// Edit column of info table
	editSubsectionInfoTableColumn: (state, payload) => {
		let level = state.section_level;
		let active_subsection_id = state.active_subsection_id;

		state.subsections_data[level][active_subsection_id]['info_table']['columns'][payload.order_number]['name'] = payload.name;
		state.subsections_data[level][active_subsection_id]['info_table']['columns'][payload.order_number]['description'] = payload.description;
	},

	// Delete info table column
	deleteSubsectionInfoTableColumn: (state, payload) => {
		let level = state.section_level;
		let active_subsection_id = state.active_subsection_id;

		state.subsections_data[level][active_subsection_id]['info_table'] = payload.data;
	},

	// Add new row to array
	addSubsectionTableRow: (state, payload) => {
		let level = state.section_level;
		let subsection_id = state.active_subsection_id;

		// Save subsections data to temp
		let temp = state.subsections_data;

		// Force refresh for subsections data
		state.subsections_data = null;
		temp[level][subsection_id].data[payload.data.row_id] = payload.data.data;
		state.subsections_data = temp;
	},

	// Add new row to info table
	addSubsectionInfoTableRow: (state, payload) => {
		let level = state.section_level;
		let subsection_id = state.active_subsection_id;

		// Save subsections data to temp
		let temp = state.subsections_data[level][subsection_id].info_table.data;

		// Force refresh for subsections data
		state.subsections_data[level][subsection_id].info_table.data = null;
		temp[payload.row_id] = {};
		temp[payload.row_id].data = payload.data;
		temp[payload.row_id].level = '1';
		state.subsections_data[level][subsection_id].info_table.data = temp;
	},

	// Change level of a row in info table
	editSubsectionInfoTableRowLevel: (state, payload) => {
		let level = state.section_level;
		let subsection_id = state.active_subsection_id;

		// Save changed row level
		state.subsections_data[level][subsection_id].info_table.data[payload.row_id].level = payload.level;
	},

	// Remove row from array
	deleteSubsectionTableRow: (state, payload) => {
		let level = state.section_level;
		let active_subsection_id = state.active_subsection_id;

		delete state.subsections_data[level][active_subsection_id]['data'][payload.row_id];

		// Force refresh - VUE SET...?
		var temp = state.subsections_data;
		state.subsections_data = {};
		state.subsections_data = temp;
	},

	// Remove row from array
	deleteSubsectionInfoTableRow: (state, payload) => {
		let level = state.section_level;
		let active_subsection_id = state.active_subsection_id;

		delete state.subsections_data[level][active_subsection_id].info_table.data[payload.row_id];

		// Force refresh - VUE SET...?
		var temp = state.subsections_data[level][active_subsection_id].info_table.data;
		state.subsections_data[level][active_subsection_id].info_table.data = {};
		state.subsections_data[level][active_subsection_id].info_table.data = temp;
	},

	// Upload data in table
	uploadTableDataAttachment: (state, payload) => {
		let level = state.section_level;
		let subsection_id = state.active_subsection_id;

		// Save subsections data to temp
		let temp = state.subsections_data;

		// Force refresh for subsections data
		state.subsections_data = null;
		temp[level][subsection_id].data[payload.row_id][payload.col_id] = {
			name: payload.file_name,
			file_url: payload.file_url, file_name: payload.upload_file_name
		};
		state.subsections_data = temp;
	},

	// Delete data in table
	deleteTableDataAttachment: (state, payload) => {
		let level = state.section_level;
		let subsection_id = state.active_subsection_id;

		delete state.subsections_data[level][subsection_id]['data'][payload.row_id][payload.col_id];

		// Force refresh - VUE SET...?
		var temp = state.subsections_data;
		state.subsections_data = {};
		state.subsections_data = temp;
	},

	// workplantemporary
	staticHTMLTableStringSubsection: (state, payload) => {
		state.staticHTMLTableStringSubsection = payload;
	},

	// Add question in subsection
	addSubsectionQuestion: (state, payload) => {
		let level = state.section_level;
		let active_subsection_id = state.active_subsection_id;

		if(!state.subsections_data[level][active_subsection_id].questions)
			Vue.set(state.subsections_data[level][active_subsection_id], 'questions', []);

		Vue.set(state.subsections_data[level][active_subsection_id].questions, payload.id, {});
		Vue.set(state.subsections_data[level][active_subsection_id].questions[payload.id], 'id', payload.id);
		Vue.set(state.subsections_data[level][active_subsection_id].questions[payload.id], 'title', payload.title);
		Vue.set(state.subsections_data[level][active_subsection_id].questions[payload.id], 'description', payload.description);
		Vue.set(state.subsections_data[level][active_subsection_id].questions[payload.id], 'subsection_id', active_subsection_id);
		Vue.set(state.subsections_data[level][active_subsection_id].questions[payload.id], 'order_number', payload.order_number);
		Vue.set(state.subsections_data[level][active_subsection_id].questions[payload.id], 'answer', '');
		Vue.set(state.subsections_data[level][active_subsection_id].questions[payload.id], 'position', payload.position);
		Vue.set(state.subsections_data[level][active_subsection_id].questions[payload.id], 'united_measures', payload.united_measures);
		Vue.set(state.subsections_data[level][active_subsection_id].questions[payload.id], 'mandatory', payload.mandatory);

		//update statistics
		if(payload.mandatory == 1){
			let section_id = router.currentRoute.params.section_id;

			let cnt_mand = sections_list.state.sections_statistics[section_id].stat_count_mandatory + 1;
			let cnt_mand_solv = sections_list.state.sections_statistics[section_id].stat_count_solved_mandatory;

			sections_list.state.sections_statistics[section_id].stat_count_mandatory = cnt_mand;
			sections_list.state.sections_statistics[section_id].stat_percantage = Math.floor(cnt_mand_solv / cnt_mand * 100);

			sections_list.state.sections_statistics[section_id].stat_count_all + 1;
		}
	},

	// Edit question in subsection
	editSubsectionQuestion: (state, payload) => {
		let level = state.section_level;
		let active_subsection_id = state.active_subsection_id;

		state.subsections_data[level][active_subsection_id].questions[payload.id].title = payload.title;
		state.subsections_data[level][active_subsection_id].questions[payload.id].description = payload.description;
		state.subsections_data[level][active_subsection_id].questions[payload.id].position = payload.position;
		state.subsections_data[level][active_subsection_id].questions[payload.id].united_measures = payload.united_measures;
		state.subsections_data[level][active_subsection_id].questions[payload.id].mandatory = payload.mandatory;
	},

	// Delete question in subsection
	deleteSubsectionQuestion: (state, payload) => {
		let level = state.section_level;
		let active_subsection_id = state.active_subsection_id;

		if(Object.keys(state.subsections_data[level][active_subsection_id].questions).length > 1){
			let deleted_order_num = state.subsections_data[level][active_subsection_id].questions[payload.id].order_number;
			let deleted_position = state.subsections_data[level][active_subsection_id].questions[payload.id].position;

			//delete question
			Vue.delete(state.subsections_data[level][active_subsection_id].questions, payload.id);

			//looop trough all question in subsection
			for(let q_id in state.subsections_data[level][active_subsection_id].questions){
				//if in same position and if order number is greater
				if(state.subsections_data[level][active_subsection_id].questions[q_id].position == deleted_position
					&& state.subsections_data[level][active_subsection_id].questions[q_id].order_number > deleted_order_num){
						//reduce order number by 1
						Vue.set(state.subsections_data[level][active_subsection_id].questions[q_id], 'order_number', 
							(state.subsections_data[level][active_subsection_id].questions[q_id].order_number-1));
				}
			}
		}
		else
			Vue.set(state.subsections_data[level][active_subsection_id], 'questions', null);
	},

	//save question answer
	saveSubsectionQuestionAnswer: (state, payload) => {
		let level = state.section_level;
		let active_subsection_id = state.active_subsection_id;

		Vue.set(state.subsections_data[level][active_subsection_id].questions[payload.question_id], 'answer', payload.value);
	},
};


const actions = {

	// Change section level
	changeLevel: ({ commit }, level) => {
		//timeout needed because question gets focus on auto grow 
		//scroll to subsections
		let element = document.getElementById("subsection_list");
		if (element)
			setTimeout(function () { if (element) element.scrollIntoView({ behavior: "smooth" }) }, 150);

		commit('setLevel', level);
	},

	//Update progressbar
	updateProgressBar: ({ commit }) => {
		let section_id = router.currentRoute.params.section_id;

		let mandatory_cnt_all = 0;
		let mandatory_cnt_solv = 0;

		let all_cnt_all = 0;
		let all_cnt_solv = 0;

		let all_data = state.subsections_data;

		for (let level in all_data) {
			if (sections_list.state.sections[section_id].levels['level' + level] &&
				sections_list.state.sections[section_id].levels['level' + level].enabled == 1) {

				for (let subs in all_data[level]) {

					//go trough table
					for (let column in all_data[level][subs].columns) {

						let col_id = all_data[level][subs].columns[column].id;

						//exclude lifelong level if disabled
						if (sections_list.state.sections_statistics[section_id] && !(level == 4 && sections_list.state.sections_statistics[section_id].lifelong_learning != 1)) {
							//Only for mandatory
							if (all_data[level][subs].columns[column].mandatory == 1) {
								for (let row in all_data[level][subs].data) {
									mandatory_cnt_all++;
									if (all_data[level][subs].data[row][col_id])
										mandatory_cnt_solv++;
								}
							}
							//Also for overall stat
							/*else{
								for(let row in all_data[level][subs].data){
									all_cnt_all ++;
									if(all_data[level][subs].data[row][col_id])
										all_cnt_solv ++;
								}
							}*/
						}
					}

					//go trough questions
					for (let question in all_data[level][subs].questions) {
						//exclude lifelong level if disabled
						if (sections_list.state.sections_statistics[section_id] && 
							!(level == 4 && sections_list.state.sections_statistics[section_id].lifelong_learning != 1) &&
							all_data[level][subs].questions[question].mandatory == 1) {
							//Only for mandatory
							//if(all_data[level][subs].questions[question].mandatory == 1){
							mandatory_cnt_all++;
							if (all_data[level][subs].questions[question].answer)
								mandatory_cnt_solv++;
							//}
						}
					}
				}
			}
		}

		//all_cnt_all += mandatory_cnt_all;
		//all_cnt_solv += mandatory_cnt_solv;

		if (sections_list.state.sections_statistics[section_id])
			commit('editSectionStatistics', {
				section_id: section_id, stat_count_mandatory: mandatory_cnt_all,
				stat_count_solved_mandatory: mandatory_cnt_solv, stat_count_solved_all: all_cnt_solv, stat_count_all: all_cnt_all
			});
	},

	//Update progressbar simple
	updateProgressBarSimple: ({ commit }, payload) => {
		payload.section_id = router.currentRoute.params.section_id;
		commit('editSectionStatisticsSimple', payload);
	},

	//Update progressbar simple for adding row
	updateProgressBarSimpleAddRow: ({ commit }, payload) => {
		payload.section_id = router.currentRoute.params.section_id;
		commit('editSectionStatisticsSimpleAddRow', payload);
	},

	// Change active subsection
	changeActiveSubsection: ({ commit }, subsection_id) => {
		//timeout needed because question gets focus on auto grow 
		//scroll to subsections
		let element = document.getElementById("subsection_list");
		setTimeout(function () { if (element) element.scrollIntoView({ behavior: "smooth" }) }, 150);

		commit('setActiveSubsection', subsection_id);
	},

	// Prepare subsections data based on section id
	prepareSubsectionsData: ({ commit }, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.get('?module=sections&action=get_details&faculty=' + general_state.state.faculty.id + '&section_id=' + payload)
			.then(
				res => {
					if (res.data.error) {
						general_mutations.hideModalWorking(general_state.state);
						var error = res.data.error ? res.data.error : payload.data;
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						commit('prepareSubsectionsData', res);
						general_mutations.hideModalWorking(general_state.state);

						//timeout needed because question gets focus on auto grow 
						//scroll to subsections
						let element = document.getElementById("section_title");
						setTimeout(function () { if (element) element.scrollIntoView({ behavior: "smooth" }) }, 100);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},

	// Prepare subsections relation info table data based on section id
	prepareSubsectionsRelationInfoTableData: ({ commit }, payload) => {
		axios.get('?module=sections&action=get_relation_info_tables&faculty=' + general_state.state.faculty.id + '&section_id=' + payload)
			.then(
				res => {
					if (res.data.error) {
						general_mutations.hideModalWorking(general_state.state);
						var error = res.data.error ? res.data.error : payload.data;
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						commit('prepareSubsectionsRelationInfoTableData', res);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});	
	},

	// Add new page 
	addSectionPage: ({ commit }, payload) => {
		general_mutations.showModalWorking(general_state.state);

		let section_id = router.currentRoute.params.section_id;
		axios.post('?module=sections&action=add_page&section_id=' + section_id, payload)
			.then(
				res => {
					if (!res.data.success) {
						general_mutations.hideModalWorking(general_state.state);
						var error = res.data.error ? res.data.error : payload.data;
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						commit('addSectionPage', payload);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},

	// Edit existing page 
	editSectionPage: ({ commit }, payload) => {
		general_mutations.showModalWorking(general_state.state);

		let section_id = router.currentRoute.params.section_id;
		axios.post('?module=sections&action=edit_page&section_id=' + section_id + '&level=' + state.section_level, payload)
			.then(
				res => {
					if (!res.data.success) {
						general_mutations.hideModalWorking(general_state.state);
						var error = res.data.error ? res.data.error : payload.data;
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						commit('editSectionPage', payload);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},

	// Edit existing page 
	deleteSectionPage: ({ commit, dispatch }, payload) => {
		general_mutations.showModalWorking(general_state.state);

		let section_id = router.currentRoute.params.section_id;
		axios.post('?module=sections&action=delete_page&section_id=' + section_id + '&faculty=' + general_state.state.faculty.id + '&level=' + state.section_level)
			.then(
				res => {
					if (!res.data.success) {
						general_mutations.hideModalWorking(general_state.state);
						var error = res.data.error ? res.data.error : payload.data;
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						commit('deleteSectionPage', res);
						dispatch('updateProgressBar');
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},

	// Add new subsection 
	addSubsection: ({ commit }, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=subsections&action=add_subsection&section_id=' + payload.section_id + '&faculty=' + general_state.state.faculty.id + '&level=' + state.section_level, payload)
			.then(
				res => {
					if (!res.data.success) {
						general_mutations.hideModalWorking(general_state.state);
						var error = res.data.error ? res.data.error : payload.data;
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						commit('addSubsection', res);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},

	// Edit existing subsection 
	editSubsection: ({ commit }, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=subsections&action=edit_subsection&subsection_id=' + state.active_subsection_id, payload)
			.then(
				res => {
					if (!res.data.success) {
						general_mutations.hideModalWorking(general_state.state);
						var error = res.data.error ? res.data.error : payload.data;
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						commit('editSubsection', payload);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},

	// Edit existing subsection 
	deleteSubsection: ({ commit, dispatch }, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=subsections&action=delete_subsection&subsection_id=' + state.active_subsection_id)
			.then(
				res => {
					if (!res.data.success) {
						general_mutations.hideModalWorking(general_state.state);
						var error = res.data.error ? res.data.error : payload.data;
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						commit('deleteSubsection');
						dispatch('updateProgressBar');
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},

	// Add new column 
	addSubsectionTableColumn: ({ commit, dispatch }, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=subsection_table&action=add_column&faculty=' + general_state.state.faculty.id + '&section_id=' + payload.section_id + '&subsection_id=' + state.active_subsection_id, payload)
			.then(
				res => {
					if (!res.data.success) {
						general_mutations.hideModalWorking(general_state.state);
						var error = res.data.error ? res.data.error : payload.data;
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						commit('refreshSubsectionsData', res);
						dispatch('updateProgressBar');
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},

	// Add new column in info table
	addSubsectionInfoTableColumn: ({ commit }, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=subsection_info_table&action=add_column&subsection_id=' + state.active_subsection_id, payload)
			.then(
				res => {
					if (!res.data.success) {
						general_mutations.hideModalWorking(general_state.state);
						var error = res.data.error ? res.data.error : payload.data;
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						commit('addSubsectionInfoTableColumn', res.data);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},


	// Edit new column 
	editSubsectionTableColumn: ({ commit, dispatch }, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=subsection_table&action=edit_column&col_id=' + payload.column_id, payload)
			.then(
				res => {
					if (!res.data.success) {
						general_mutations.hideModalWorking(general_state.state);
						var error = res.data.error ? res.data.error : payload;
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						commit('editSubsectionTableColumn', payload);
						general_mutations.hideModalWorking(general_state.state);
						dispatch('updateProgressBar');
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},

	// Edit info table column 
	editSubsectionInfoTableColumn: ({ commit, dispatch }, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=subsection_info_table&action=edit_column&subsection_id=' + state.active_subsection_id+'&col_id=' + payload.column_id, payload)
			.then(
				res => {
					if (!res.data.success) {
						general_mutations.hideModalWorking(general_state.state);
						var error = res.data.error ? res.data.error : payload;
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						commit('editSubsectionInfoTableColumn', payload);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},

	// Delete new column 
	deleteSubsectionTableColumn: ({ commit, dispatch }, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=subsection_table&action=delete_column&faculty=' + general_state.state.faculty.id + '&section_id=' + payload.section_id + '&subsection_id=' + state.active_subsection_id + '&col_id=' + payload.column_id)
			.then(
				res => {
					if (!res.data.success) {
						general_mutations.hideModalWorking(general_state.state);
						var error = res.data.error ? res.data.error : payload;
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						commit('refreshSubsectionsData', res);
						dispatch('updateProgressBar');
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},

	// Delete info table column 
	deleteSubsectionInfoTableColumn: ({ commit }, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=subsection_info_table&action=delete_column&subsection_id=' + state.active_subsection_id + '&col_id=' + payload.column_id)
			.then(
				res => {
					if (!res.data.success) {
						general_mutations.hideModalWorking(general_state.state);
						var error = res.data.error ? res.data.error : payload;
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						commit('deleteSubsectionInfoTableColumn', res.data);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},

	// Add info table row 
	addSubsectionInfoTableRow: ({ commit }, payload) => {
		axios.post('?module=subsection_info_table&action=add_row&subsection_id=' + state.active_subsection_id)
			.then(
				res => {
					if (!res.data.success) {
						var error = res.data.error ? res.data.error : payload.data;
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						commit('addSubsectionInfoTableRow', res.data);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});	
	},

	// Change info table row level
	editSubsectionInfoTableRowLevel: ({ commit }, payload) => {
		axios.post('?module=subsection_info_table&action=edit_row_level&row_id=' + payload.row_id + '&level=' + payload.level)
			.then(
				res => {
					if (!res.data.success) {
						var error = res.data.error ? res.data.error : payload.data;
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						commit('editSubsectionInfoTableRowLevel', payload);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});	
	},

	// Add new row 
	addSubsectionTableRow: ({ commit, dispatch }, payload) => {
		axios.post('?module=subsection_table&action=add_row&faculty=' + general_state.state.faculty.id + '&subsection_id=' + state.active_subsection_id)
			.then(
				res => {
					if (!res.data.success) {
						var error = res.data.error ? res.data.error : payload.data;
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						commit('addSubsectionTableRow', res);
						dispatch('updateProgressBarSimpleAddRow', payload);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});	
	},

	// Delete row
	deleteSubsectionTableRow: ({ commit, dispatch }, payload) => {
		axios.post('?module=subsection_table&action=delete_row&row_id=' + payload.row_id)
			.then(
				res => {
					if (!res.data.success) {
						var error = res.data.error ? res.data.error : payload.data;
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						commit('deleteSubsectionTableRow', payload);
						dispatch('updateProgressBar', payload);

					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});	
	},

	// Delete info table row
	deleteSubsectionInfoTableRow: ({ commit }, payload) => {
		axios.post('?module=subsection_info_table&action=delete_row&row_id=' + payload.row_id)
			.then(
				res => {
					if (!res.data.success) {
						var error = res.data.error ? res.data.error : payload.data;
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						commit('deleteSubsectionInfoTableRow', payload);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});	
	},

	// Save data 
	saveSectionData: ({ commit, dispatch }, payload) => {
		general_mutations.showModalWorking(general_state.state);

		axios.post('?module=subsection_table&action=save_data&faculty=' + general_state.state.faculty.id + '&section_id=' + payload.section_id, payload)
			.then(
				res => {
					if (!res.data.success) {
						var error = res.data.error ? res.data.error : payload.data;
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						dispatch('prepareSectionsStat');
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},

	// Update cell
	updateCell: ({ commit, dispatch }, payload) => {
		axios.post('?module=subsection_table&action=update_cell', payload)
			.then(
				res => {
					if (!res.data.success) {
						var error = res.data.error ? res.data.error : 'Nepravilen vnos';
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						//dispatch('updateProgressBar');
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});	
	},

	// Update info table cell
	updateInfoTableCell: ({ commit }, payload) => {
		axios.post('?module=subsection_info_table&action=update_cell', payload)
			.then(
				res => {
					if (!res.data.success) {
						var error = res.data.error ? res.data.error : 'Nepravilen vnos';
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						//dispatch('updateProgressBar');
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});	
	},

	// Delete attachment
	deleteTableDataAttachment: ({ commit, dispatch }, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=upload&action=delete_table_upload&row_id=' + payload.row_id + '&col_id=' + payload.col_id)
			.then(
				res => {
					if (!res.data.success) {
						var error = res.data.error ? res.data.error : formData;
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						commit('deleteTableDataAttachment', payload);
						dispatch('updateProgressBarSimple', { mandatory: payload.mandatory, edit: -1 });
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},


	// Upload attachment for data table
	uploadTableDataAttachment: ({ commit, dispatch }, payload) => {
		general_mutations.showModalWorking(general_state.state);

		let formData = new FormData();
		formData.append('file', payload.file);
		formData.set('name', payload.file_name);

		axios.post('?module=upload&action=upload_table_file&row_id=' + payload.row_id + '&col_id=' + payload.col_id, formData)
			.then(
				res => {
					if (!res.data.success) {
						var error = res.data.error ? res.data.error : res.data;
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						if (res.data.upload_url && res.data.upload_file_name) {
							payload.file_url = res.data.upload_url;
							payload.upload_file_name = res.data.upload_file_name;
						}
						else
							general_mutations.showModal(general_state.state, { component: 'ModalError', data: 'Upload URL or name missing in response!' })

						commit('uploadTableDataAttachment', payload);
						dispatch('updateProgressBarSimple', { mandatory: payload.mandatory, edit: 1 });
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},

	// Get string of static html table
	getStaticHTMLTableString: ({ commit }, payload) => {
		axios.get('?module=subsections&action=get_static_table&name=' + payload.file_name + '&section_id=' + payload.section_id)
			.then(
				res => {
					if (payload.table == 'faculty_sub') {
						if (res.data && res.data.data)
							commit('staticHTMLTableStringSubsection', res.data.data);
						else
							commit('staticHTMLTableStringSubsection', '');
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});	
	},

	// Export subsection table for import data
	exportSubsectionTableImportFile: ({ commit }) => {
		general_mutations.showModalWorking(general_state.state);
		axios.get('?module=export&action=get_subsection_table_import_file&subsection_id=' + state.active_subsection_id + '&faculty=' + general_state.state.faculty.id)
			.then(
				res => {
					if (!res.data.success) {
						general_mutations.hideModalWorking(general_state.state);
						var error = res.data.error ? res.data.error : 'Napaka';
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						general_mutations.hideModalWorking(general_state.state);
						window.open(res.data.export_link);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},

	// Import subsection table from file
	importDataSubsectionTable: ({ commit, dispatch }, payload) => {

		let level = state.section_level;
		let subsection_id = state.active_subsection_id;

		general_mutations.showModalWorking(general_state.state);

        let formData = new FormData();
        formData.append('file', payload.file);

		let section_id = router.currentRoute.params.section_id;

		axios.post('?module=subsection_table&action=import_subsection_table_data&subsection_id=' + state.active_subsection_id + 
			'&faculty=' + general_state.state.faculty.id + '&section_id=' + section_id, formData)
			.then(
				res => {
					if (!res.data.success) {
						general_mutations.hideModalWorking(general_state.state);
						var error = res.data.error ? res.data.error : 'Napaka pri uvozu podatkov';

						if(res.data.error == 'bad_data')
							general_mutations.showModal(general_state.state, {component: 'ModalError', data: 'Struktura in/ali podatki v datoteki se ne ujemajo s tabelo podpodročja!'})
						else
							general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
					}
					else {
						commit('refreshSubsectionsData', res);
						dispatch('updateProgressBar');
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},

	// Export section data
	exportSectionData: ({ commit }, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.get('?module=export&action=get_section_export&section_id=' + payload.section_id + '&format=' + payload.format + '&faculty=' + general_state.state.faculty.id)
			.then(
				res => {
					if (!res.data.success) {
						general_mutations.hideModalWorking(general_state.state);
						var error = res.data.error ? res.data.error : payload.data;
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						general_mutations.hideModalWorking(general_state.state);
						window.open(res.data.export_link);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},

	// Export united measures section data
	exportUnitedMeasuresSectionData: ({ commit }, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.get('?module=export&action=get_ukrepi_section_export&section_id=' + payload.section_id + '&format=' + payload.format + '&faculty=' + general_state.state.faculty.id)
			.then(
				res => {
					if (!res.data.success) {
						general_mutations.hideModalWorking(general_state.state);
						var error = res.data.error ? res.data.error : payload.data;
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						general_mutations.hideModalWorking(general_state.state);
						window.open(res.data.export_link);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},

	// Copy table to all faculties
	CopyTableOverrideData: ({ commit }) => {
		general_mutations.showModalWorking(general_state.state);
		axios.get('?module=subsection_table&action=save_predefined_data&subsection_id=' + state.active_subsection_id + '&faculty=' + general_state.state.faculty.id)
			.then(
				res => {
					if (!res.data.success) {
						general_mutations.hideModalWorking(general_state.state);
						var error = res.data.error ? res.data.error : res.data;
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: error })
					}
					else {
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, { component: 'ModalWarning', data: { title: "Vsebina tabele kopirana", description: "Vsebina tabele podpodročja se je uspešno kopirala v ostale članice" } })
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},

	// Add question in subsection
	addSubsectionQuestion: ({ commit }, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=subsection_question&action=add_question&subsection_id=' + state.active_subsection_id, payload)
			.then(
				res => {
					if (res.data.error) {
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: res.data.error })
					}
					else {
						payload.id = res.data.question_id;
						commit('addSubsectionQuestion', payload);
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},

	// Edit question in subsection
	editSubsectionQuestion: ({ commit, dispatch }, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=subsection_question&action=edit_question&question_id=' + payload.id, payload)
			.then(
				res => {
					if (res.data.error) {
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: res.data.error })
					}
					else {
						commit('editSubsectionQuestion', payload);
						dispatch('updateProgressBar');
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},

	// Delete question in subsection
	deleteSubsectionQuestion: ({ commit, dispatch }, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.get('?module=subsection_question&action=delete_question&question_id=' + payload.id)
			.then(
				res => {
					if (res.data.error) {
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: res.data.error })
					}
					else {
						commit('deleteSubsectionQuestion', payload);
						dispatch('updateProgressBar');
						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},

	// Save answer
	saveSubsectionQuestionAnswer: ({ commit, dispatch }, payload) => {
		//general_mutations.showModalWorking(general_state.state);
		axios.post('?module=subsection_question&action=save_question_answer&question_id=' + payload.question_id + '&faculty=' + general_state.state.faculty.id, payload)
			.then(
				res => {
					if (res.data.error) {
						//general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, { component: 'ModalError', data: res.data.error })
					}
					else {
						commit('saveSubsectionQuestionAnswer', payload);
						dispatch('updateProgressBar', payload);
						//general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});	
	},

	// Get files  package for whole column (and all faculties if specified)
	exportFilesColumn: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		payload.faculty = general_state.state.faculty.id;

		axios.post('?module=upload&action=get_subsection_all_files_by_column', payload)
		.then(
			res => {
				if(!res.data.success){				
					var error = res.data.error ? res.data.error : "Napaka";
					general_mutations.hideModalWorking(general_state.state);
					if(error == "no_files"){
						general_mutations.showModal(general_state.state, {component: 'ModalWarning', 
						data: {title:"Ni datotek", description:"Za ta stolpec še naloženih datotek"}})
					}
					else if(error == "too_big"){
						general_mutations.showModal(general_state.state, {component: 'ModalWarning', 
						data: {title:"Prenos prevelik", description:"Omejitev prenosa datoteke je 114MB. Poskusite ponovno s prenosom manjših/razdeljenih paketov."}})
					}
					else
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
				}
                else{
                    general_mutations.hideModalWorking(general_state.state);
					window.open(res.data.download_link);
                }
			}
		)
		.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},
};


export default {
	state,
	getters,
	actions,
	mutations
}