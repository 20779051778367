<template>
    <div class="content sections_dashboard" v-if="getUserType > 0">
        <div class="sections_upper_holder">
            <div class="title_with_action">
                <h2>
                    Seznam vseh programov za leto {{ year }}
                    <font-awesome-icon v-if="(getUserType == 3 || getUserType == 2) && dashboard_data_by_program.length > 0"
                        class="as_link icon" :title="toggle_hide_programs_text"
                        @click="toggleHidePrograms(programs_hidden)"
                        :icon="['fas', (programs_hidden == 0) ? 'unlock' : 'lock']" />
                </h2>
            </div>
        </div>

        <div class=" dashboard_tools">
            <div class="view_by" v-if="getUserType == 2">
                <ul>
                    <li :class="{active: view_by == 0}" @click="view_by = 0; resetFilters();"><span class="item_text">Po
                            fakultetah</span></li>
                    <li :class="{active: view_by == 1}" @click="view_by = 1; resetFilters();"><span class="item_text">Po
                            programih</span></li>
                </ul>
            </div>

            <div class="dashboard_tools_top">
                <div class="dashboard_search_bar">
                    <!-- Dashboard Search bar -->
                    <input type="text" v-model="search" placeholder="Išči" />
                </div>

                <div v-show="(getUserType == 3 || getUserType == 2) && view_by == 1">
                    <label for="id_faculty_select">Fakulteta:</label>
                    <select id="id_faculty_select" v-model="id_faculty_select">
                        <option value="0">vse</option>
                        <option v-for="faculty in getSortedFacultiesByAcronym" v-bind:value="faculty.id"
                            v-bind:key="faculty.id">
                            {{ faculty.acronym }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="dashboard_tools_stamp_filter_group">
                <div v-show="view_by == 0" class="dashboard_tools_stamp_filter in_group">
                    <div class="filled"
                        :class="{deadline_status_empty: !stamp_filter_active.submitted.submitted, deadline_status: stamp_filter_active.submitted.submitted}"
                        @click="stamp_filter_active.submitted.submitted = !stamp_filter_active.submitted.submitted;">
                        oddano</div>
                    <div class="after_late"
                        :class="{deadline_status_empty: !stamp_filter_active.submitted.unsubmitted, deadline_status: stamp_filter_active.submitted.unsubmitted}"
                        @click="stamp_filter_active.submitted.unsubmitted = !stamp_filter_active.submitted.unsubmitted;">
                        neoddano</div>
                </div>
                <div v-show="view_by == 1" class="dashboard_tools_stamp_filter in_group">
                    <div class="filled"
                        :class="{deadline_status_empty: !stamp_filter_active.finished.finished, deadline_status: stamp_filter_active.finished.finished}"
                        @click="stamp_filter_active.finished.finished = !stamp_filter_active.finished.finished;">
                        zaključeno</div>
                    <div class="after_late"
                        :class="{deadline_status_empty: !stamp_filter_active.finished.unfinished, deadline_status: stamp_filter_active.finished.unfinished}"
                        @click="stamp_filter_active.finished.unfinished = !stamp_filter_active.finished.unfinished;">
                        nezaključeno</div>
                </div>
                <div v-show="view_by == 1" class="dashboard_tools_stamp_filter in_group">
                    <div class="basic"
                        :class="{deadline_status_empty: !stamp_filter_active.degree.first, deadline_status: stamp_filter_active.degree.first}"
                        @click="stamp_filter_active.degree.first = !stamp_filter_active.degree.first;">
                        prva</div>
                    <div class="basic"
                        :class="{deadline_status_empty: !stamp_filter_active.degree.second, deadline_status: stamp_filter_active.degree.second}"
                        @click="stamp_filter_active.degree.second = !stamp_filter_active.degree.second;">
                        druga</div>
                    <div class="basic"
                        :class="{deadline_status_empty: !stamp_filter_active.degree.third, deadline_status: stamp_filter_active.degree.third}"
                        @click="stamp_filter_active.degree.third = !stamp_filter_active.degree.third;">
                        tretja</div>
                    <div class="basic"
                        :class="{deadline_status_empty: !stamp_filter_active.degree.training, deadline_status: stamp_filter_active.degree.training}"
                        @click="stamp_filter_active.degree.training = !stamp_filter_active.degree.training;">
                        izpopolnjevanje</div>
                </div>
                <div v-show="view_by == 1" class="dashboard_tools_stamp_filter in_group">
                    <div class="preparation_late"
                        :class="{deadline_status_empty: !stamp_filter_active.mode.full, deadline_status: stamp_filter_active.mode.full}"
                        @click="stamp_filter_active.mode.full = !stamp_filter_active.mode.full;">
                        redni</div>
                    <div class="preparation_late"
                        :class="{deadline_status_empty: !stamp_filter_active.mode.part, deadline_status: stamp_filter_active.mode.part}"
                        @click="stamp_filter_active.mode.part = !stamp_filter_active.mode.part;">
                        izredni</div>
                    <div class="preparation_late"
                        :class="{deadline_status_empty: !stamp_filter_active.mode.other, deadline_status: stamp_filter_active.mode.other}"
                        @click="stamp_filter_active.mode.other = !stamp_filter_active.mode.other;">
                        drugo</div>
                </div>
            </div>
        </div>

        <!-- BY FACULTY -->
        <div v-show="view_by == 0" class="dashboard_square_list">
            <div class="dashboard_faculty_square_holder" v-for="faculty in filteredRowByFaculty" :key="faculty.id">
                <div class="dashboard_faculty_square_item">
                    <div class="dashboard_faculty_item_left">
                        <div class="as_link_grey dashboard_faculty_item_name"
                            @click="id_faculty_select = faculty.id; view_by = 1">
                            {{faculty.acronym}} - {{faculty.name}}
                        </div>
                        <br><br>
                        <div class="deadline_status" :class="get_status_by_faculties[faculty.id].class">
                            {{get_status_by_faculties[faculty.id].text}}</div>
                        <div class="dashboard_faculty_item_progress"
                            :class="get_status_by_faculties[faculty.id].progress_class">
                            Zaključeno: {{get_status_by_faculties[faculty.id].progress}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- BY PROGRAM -->
        <div v-show="view_by == 1" class="table-wrapper user_list">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th @click="sort('name')">
                            <font-awesome-icon :icon="['fas', 'sort']" />
                            Program
                        </th>
                        <th @click="sort('ul_program_id')">
                            <font-awesome-icon :icon="['fas', 'sort']" /> Šifrant
                        </th>
                        <th @click="sort('description')">
                            <font-awesome-icon :icon="['fas', 'sort']" /> Opis
                        </th>
                        <th @click="sort('stopnja')">
                            <font-awesome-icon :icon="['fas', 'sort']" />
                            Stopnja
                        </th>
                        <th @click="sort('vrsta')">
                            <font-awesome-icon :icon="['fas', 'sort']" /> Vrsta
                        </th>
                        <th @click="sort('nacin_studija')">
                            <font-awesome-icon :icon="['fas', 'sort']" /> Način
                            študija
                        </th>
                        <!--<th @click="sort('akreditacija_od')">
                            <font-awesome-icon :icon="['fas', 'sort']" />
                            Akreditacija
                        </th>-->
                        <th v-if="getUserType > 1 && id_faculty_select == 0" @click="sort('acronym')">
                            <font-awesome-icon :icon="['fas', 'sort']" />
                            Fakulteta
                        </th>
                        <th @click="sort('finished')">
                            <font-awesome-icon :icon="['fas', 'sort']" /> Zaključeno
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <router-link tag="tr" :class="{redundant: (dash_program.redundant == 1 && getUserType == 2)}" :to="{
                        name: 'programDetail',
                        params: { programs: dash_program.id, q_type: /*dash_program.stopnja == 'tretja stopnja' && year > '2019/2020' ? 4 :*/ 1 },
                    }" :key="dash_program.id" v-for="dash_program in sortedPrograms" class="pointer">
                        <td>{{ dash_program.name }}</td>
                        <td>{{ dash_program.ul_program_id }}</td>
                        <td>{{ dash_program.description }}</td>
                        <td>{{ dash_program.stopnja }}</td>
                        <td>{{ dash_program.vrsta }}</td>
                        <td>{{ dash_program.nacin_studija }}</td>
                        <!--<td>{{ dash_program.akreditacija_od }}</td>-->
                        <td v-if="getUserType > 1 && id_faculty_select == 0">
                            {{ getFacultyAcronym(dash_program.faculty_id) }}
                        </td>
                        <td class="align_center">
                            <font-awesome-icon v-if="dash_program.finished == 1" :icon="['fas', 'check']" />
                        </td>
                    </router-link>

                    <!-- pagination -->
                    <tr v-show="maxPage > 1">
                        <td colspan="6">
                            <ul-pagination-row :maxPage="maxPage" :currentPage="currentPage"
                                @change_page="set_current_page"></ul-pagination-row>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>


<script>
import { mapActions } from "vuex";
import PaginationRow from '../general/PaginationRow.vue';

export default {
    name: "EvalvationDashboardProgramListItemTable",
    components: {
        'ul-pagination-row': PaginationRow,
    },
    data: function () {
        return {
            search: "",
            currentSort: "name",
            currentSortDir: "asc",
            id_faculty_select: "0",
            currentPage: 1,
            perPage: 12,
            maxPage: 1,
            stamp_filter_active: {
                submitted: {
                    submitted: false,
                    unsubmitted: false,
                },
                finished: {
                    finished: false,
                    unfinished: false,
                },
                degree: {
                    first: false,
                    second: false,
                    third: false,
                    training: false,
                },
                mode: {
                    other: false,
                    full: false,
                    part: false,
                }
            },
            view_by: 0,
        };
    },
    computed: {
        year() {
            return this.$store.getters.year;
        },
        // Get loged user type
        getUserType() {
            return this.$store.getters.getUser.type;
        },

        programs_hidden() {
            //if at least one of programs ishidden, hide all
            for (let program of this.dashboard_data_by_program) {
                if (program.hidden == 1)
                    return 1;
            }
            return 0;
        },

        toggle_hide_programs_text() {
            return (this.programs_hidden) == 0 ? 'Programi so prikazani uporabnikom' : 'Programi so skriti uporabnikom';
        },

        dashboard_data_by_program() {
            let data = {};
            if (this.getUserType > 1)
                data = this.$store.getters.evalvation_programs_all
                    ? this.$store.getters.evalvation_programs_all
                    : {};
            else
                data = this.$store.getters.evalvation_programs_user
                    ? this.$store.getters.evalvation_programs_user
                    : {};

            let values = Object.values(data);
            //this.num_programs = values.length;

            return values;
        },
        filteredRowByProgram: function () {
            return this.dashboard_data_by_program.filter((data) => {
                let faculty_data = this.getFaculties[data.faculty_id];

                //Stamps filter
                if (!(Object.values(this.stamp_filter_active.finished).every(Boolean) || (this.stamp_filter_active.finished.finished === false &&
                    this.stamp_filter_active.finished.unfinished === false))) {

                    if ((this.stamp_filter_active.finished.finished && data.finished == 0) ||
                        (this.stamp_filter_active.finished.unfinished && data.finished == 1))
                        return false;
                }

                if (!(Object.values(this.stamp_filter_active.degree).every(Boolean) || (this.stamp_filter_active.degree.first === false
                    && this.stamp_filter_active.degree.second === false && this.stamp_filter_active.degree.third === false
                    && this.stamp_filter_active.degree.training === false))) {

                    if ((!this.stamp_filter_active.degree.first && data.stopnja.includes('prva'))
                        || (!this.stamp_filter_active.degree.second && data.stopnja.includes('druga'))
                        || (!this.stamp_filter_active.degree.third && data.stopnja.includes('tretja'))
                        || (!this.stamp_filter_active.degree.training && data.stopnja.includes('izpopolnjevanje')))
                        return false;
                }

                if (!(Object.values(this.stamp_filter_active.mode).every(Boolean) || (this.stamp_filter_active.mode.other === false
                    && this.stamp_filter_active.mode.full === false && this.stamp_filter_active.mode.part === false))) {

                    if (!((this.stamp_filter_active.mode.full && (data.nacin_studija.includes('redni,') || data.nacin_studija == 'redni'))
                        || (this.stamp_filter_active.mode.part && data.nacin_studija.includes('izredni')
                            || (this.stamp_filter_active.mode.other && !data.nacin_studija.includes('redni')))))
                        return false;

                }

                //filter
                let filter_string = "";
                if (faculty_data) {
                    filter_string =
                        faculty_data["acronym"] +
                        " " +
                        faculty_data["name"] +
                        " " +
                        data["name"] +
                        " " +
                        /*data["stopnja"] +
                        " " +*/
                        data["vrsta"] +
                        " " +
                        /*data["nacin_studija"] +
                        " " +*/
                        //data["akreditacija_od"] +
                        //" " +
                        data["ul_program_id"] +
                        " " +
                        data["description"] +
                        " " +
                        data["stopnja"] +
                        ". stopnja";

                    //so we can sort trough acronym
                    data.acronym = faculty_data["acronym"];
                }

                if (filter_string.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 &&
                    (this.id_faculty_select == 0 || this.id_faculty_select == data.faculty_id)) {
                    return true;
                }
                return false;
            });
        },

        filteredRowByFaculty: function () {
            let faculties = Object.values(this.getFaculties);
            let filtered_faculties = faculties.filter((data) => {

                //Stamps filter
                if (!(Object.values(this.stamp_filter_active.submitted).every(Boolean) || (this.stamp_filter_active.submitted.submitted === false &&
                    this.stamp_filter_active.submitted.unsubmitted === false))) {

                    if ((this.stamp_filter_active.submitted.submitted && this.get_status_by_faculties[data.id].class == 'after_late') ||
                        (this.stamp_filter_active.submitted.unsubmitted && this.get_status_by_faculties[data.id].class == 'filled'))
                        return false;
                }

                //filter
                let filter_string = data["acronym"] + " " + data["name"];

                if (filter_string.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) {
                    return true;
                }
                return false;
            });

            return filtered_faculties.sort(function (a, b) {
                return a.acronym > b.acronym ? 1 : -1;
            });
        },

        // Get all faculties
        getFaculties() {
            return this.$store.getters.getFaculties;
        },
        getSortedFacultiesByAcronym() {
            return this.$store.getters.getSortedFacultiesByAcronym;
        },
        //create and get object of all statuses by faculties
        get_status_by_faculties() {
            let result = {};
            for (let faculty_id in this.getFaculties) {
                let finished_programs = 0;
                let submitted_programs = 0;
                let programs = this.dashboard_data_by_program.filter((data) => {
                    if (faculty_id == data.faculty_id) {
                        if (data.finished == 1)
                            finished_programs++;
                        if (data.submitted == 1)
                            submitted_programs++;
                        return true;
                    }
                    return false;
                });
                result[faculty_id] = {};
                result[faculty_id].class = (submitted_programs == programs.length) ? 'filled' : 'after_late';
                result[faculty_id].text = (submitted_programs == programs.length) ? 'oddano' : 'neoddano';
                result[faculty_id].progress = finished_programs + ' / ' + programs.length;
                result[faculty_id].progress_class = (submitted_programs == programs.length && finished_programs != programs.length) ? 'red' :
                    (submitted_programs != programs.length && finished_programs == programs.length) ? 'green' : '';
            }
            return result;
        },

        // Sortiranje tabele uporabnikov
        sortedPrograms: function () {
            let col = Intl.Collator("sl");
            let res = this.filteredRowByProgram.sort((a, b) => {
                let modifier = 1;
                if (this.currentSortDir === "desc") modifier = -1;
                if (col.compare(a[this.currentSort], b[this.currentSort]) < 0)
                    return -1 * modifier;
                if (col.compare(a[this.currentSort], b[this.currentSort]) > 0)
                    return 1 * modifier;
                return 0;
            });

            //Calculate number of all pages
            this.maxPage = Math.ceil(res.length / this.perPage);
            //Return only items on given page
            return res.slice((this.currentPage * this.perPage) - this.perPage, this.currentPage * this.perPage);
        },
    },
    methods: {
        ...mapActions(["getProgramsListAll", "getProgramsListForFaculty", "toggleHidePrograms"]),

        getFacultyAcronym(fid) {
            if (this.getFaculties[fid]) return this.getFaculties[fid].acronym;
            return "";
        },

        sort: function (s) {
            //if s == current sort, reverse
            if (s === this.currentSort) {
                this.currentSortDir =
                    this.currentSortDir === "asc" ? "desc" : "asc";
            }
            this.currentSort = s;
        },

        //Change current page
        set_current_page(page) {
            this.currentPage = page;
        },

        resetFilters() {
            this.search = "";
            for (let group in this.stamp_filter_active) {
                for (let filter in this.stamp_filter_active[group]) {
                    this.stamp_filter_active[group][filter] = false;
                }
            }

        }
    },
    watch: {
        filteredRowByProgram(newVal, oldVal) {
            this.set_current_page(1);
        }
    },
    created() {
        if (this.dashboard_data_by_program.length == 0) {
            if (this.getUserType > 1) this.getProgramsListAll();
            else if (this.getUserType == 1) this.getProgramsListForFaculty();
        }
        if(this.getUserType == 3){
            this.view_by = 1;
        }
    },
};
</script>


<style lang="scss" scoped>
@import "../../scss/style.scss";
</style>
