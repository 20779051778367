<template>
    <div class="content sections_list_holder">

        <div class="sections_upper_holder">
            <div class="title_with_action">
                <h2>
                    Pregled področij v letu {{ year }}
                    <font-awesome-icon v-if="getUserType == 2 && filteredSectionsByOrder.length > 0"
                        class="as_link icon" :title="toggle_hide_sections_text"
                        @click="toggleHideSections({hide: sections_hidden, tab: getTabCode})"
                        :icon="['fas', (sections_hidden == 0) ? 'unlock' : 'lock']" />
                </h2>
            </div>

            <div v-if="getUserType == 2 || (sections_hidden == 0 && filteredSectionsByOrder.length > 0)" class="submit_sections_buttons">
                <!-- Button for repealing submitting sections data -->
                <div class="button_holder submit_sections_data"
                    v-if="Object.keys(getSectionStatistics).length > 0 && getUserType == 2">
                    <div v-if="allSubmitted" class="button large" @click="repeal_submit_sections_all()">
                        Razveljavi oddajo
                    </div>
                </div>

                <!-- Button for submitting sections data -->
                <div class="button_holder submit_sections_data"
                    v-if="(getUserType == 1 || getUserType == 2) && Object.keys(getSectionStatistics).length > 0">
                    <div v-if="!allSubmitted" class="button large" @click="submit_sections_all()">
                        Oddaj
                    </div>
                    <div v-else class="button large disabled" title="Vnos je zaključen, ni sprememb">
                        Oddano
                    </div>
                </div>
            </div>
        </div>

        <div v-if="getUserType != 2 && sections_hidden === 1" class="already_submitted_warning warning">
            <p>Zbiranje podatkov za modul {{ getTabCode === 0 ?  'Poslovno poročanje' : 'Letni program delaß'}} se za izbrano študijsko leto še ni pričelo.</p>
        </div>

        <div class="sections_intro_row">
            <div class="sections_intro" v-if="getUserType == 2 || (get_sections_intro && get_sections_intro !== '')"
                v-bind:class="{ edit: getUserType == 2 }">
                <div class="sections_intro_text" v-if="get_sections_intro && get_sections_intro !== ''" v-html="get_sections_intro"></div>
                <div class="sections_intro_text" v-else><i>Vnesite uvod</i></div>

                <div class="button_holder" v-if="getUserType == 2">
                    <div class="subsection_edit button" @click="showModal({component: 'sections/EditSectionsIntro'})">
                        Uredi
                    </div>
                </div>
            </div>
            
            <div v-if="getUserType > 1 || (sections_hidden == 0 && filteredSectionsByOrder.length > 0)" class="file_exports">
                <div v-if="getUserType > 1" class="as_link icon" title="Izvoz vseh področij za vse fakultete"
                    @click="exportSectionDataFacultiesPackage({format: 'xlsx', tab: getTabCode})">
                    <font-awesome-icon :icon="['far', 'file-archive']" />
                </div>
                <div class="as_link icon" title="Izvoz vseh področij posebej v Word (z navodili)"
                    @click="exportSectionDataAllPackage({format: 'doc', tab: getTabCode})">
                    <font-awesome-icon class="word" :icon="['far', 'file-archive']" />
                </div>
                <div class="as_link icon" title="Izvoz vseh področij (z navodili)"
                    @click="exportSectionDataAll({format: 'xlsx', tab: getTabCode})">
                    <font-awesome-icon :icon="['far', 'file-excel']" />
                </div>
                <div class="as_link icon" title="Izvoz vseh področij (brez navodil)"
                    @click="exportSectionDataAll({format: 'doc', tab: getTabCode})">
                    <font-awesome-icon :icon="['far', 'file-word']" />
                </div>
            </div>
        </div>


        <!-- File upload - WORK IN PROGRESS -->
        <ul-sections-list-upload v-if="getUserType == 2 || (sections_hidden == 0 && filteredSectionsByOrder.length > 0)" :upload_type="1" :tab="getTabCode"></ul-sections-list-upload>


        <div v-if="getUserType == 2 || (sections_hidden == 0 && filteredSectionsByOrder.length > 0)" class="sections_list">

            <div v-if="Object.keys(sections).length == 0 && getUserType == 2"
                class="section_holder button copy_sections_button" @click="CopySectionsPreviousYear()">
                <p>Kopiraj področja iz prejšnjega leta</p>
            </div>

            <div class="section_holder" v-bind:key="section.id" v-for="section in filteredSectionsByOrder"
                v-bind:class="{ hidden: section.ukrepi == '0' && !getSectionStatistics[section.id] }">

                <div class="section_settings" v-if="getUserType == 2">
                    <span>
                        <font-awesome-icon :icon="['fas', 'pencil-alt']" class="pointer" title="Uredi področje"
                            @click="showModal({component: 'sections/EditSection', data: {section_id: section.id}})" />
                    </span>
                    <span>
                        <font-awesome-icon :icon="['fas', 'trash-alt']" class="pointer" title="Izbriši področje"
                            @click="showModal({component: 'sections/DeleteSection', data: {section_id: section.id}})" />
                    </span>
                </div>

                <router-link tag="div" class="section_link"
                    :to="{ name: 'sectionsDetail'+getSectionTabName, params: {section_id: section.id} }">

                    <!-- Hover box using CSS Tooltip (hover), ki trenutno izpiše ime področja 
                    Izpiši le prvih sto znakov-->
                    <div class="section_thumb">
                        <div class="hvrbox">
                            <img :src="getImgUrl(section.image_id)" v-if="section.image_id">
                            <!-- Hover box layer se prikaže samo, če za področje obstaja zapis.-->
                            <div class="hvrbox-layer_top" v-if="section.description.length != 0">
                                <div class="hvrbox-text"
                                    v-html="trimSectionDescription(section.description)"></div>
                            </div>
                        </div>
                    </div>


                    <span class="section_title">
                        {{ section.name }}

                        <!-- Izpis roka za oddajo (datuma) uporabniku, v kolikor ga je administrator nastavil-->
                        <div class="section_deadline" v-if="getUserType == 0 && section.deadline !== '0000-00-00'">Rok za
                            oddajo: {{getDeadlineDate(section.deadline)}}</div>
                    </span>

                </router-link>

                <!-- Progress bar                                            getTabCode == 0 popravi tudi v else if spodaj!!!!!!!-->
                <div class="shell full_width" v-if="section.ukrepi != '1' && getSectionStatistics[section.id]"
                    v-bind:class="{ full: getSectionStatistics[section.id].stat_percantage == 100 }">

                    <div class="bar" :style="{ width: getSectionStatistics[section.id].stat_percantage + '%' }"></div>

                    <div class="percentage">
                        {{ getSectionStatistics[section.id].stat_percantage }}% ({{
                        getSectionStatistics[section.id].stat_count_solved_mandatory }}/{{
                        getSectionStatistics[section.id].stat_count_mandatory }})
                    </div>
                </div>
                <!-- Progress bar -->

                <!-- Text "not visible section" -->
                <div v-else-if="section.ukrepi != '1'" class="hidden_section">
                    Za {{ getFaculty.acronym }} to področje ni vidno.
                </div>
                <!-- Text "not visible section"  -->

            </div>

            <!-- Box for adding new section -->
            <div v-if="getUserType == 2" class="section_holder new_section pointer"
                @click="showModal({component: 'sections/CreateSection'})">
                <font-awesome-icon :icon="['fas', 'plus']" class="section_link plus_icon" />
            </div>

        </div>
    </div>
</template>


<script>
import router from '../../router/router'
import { mapActions } from 'vuex';
import moment from 'moment';    // For formating dates (European standards)
import SectionsListUpload from './SectionsListUpload.vue';

export default {
    name: 'SectionsList',
    components: {
        'ul-sections-list-upload': SectionsListUpload,
    },
    data: function () {
        return {
            not_filled_data: new Object()
        };
    },
    computed: {
        year() {
            if (this.getTabCode == 1) {
                let year = this.$store.getters.year;
                return parseInt(year.split("/").pop()) + 1;
            }
            return this.$store.getters.year;
        },

        getYear() {
            return this.$store.getters.year;
        },

        getTabCode() {
            if (router.currentRoute.matched[0].path == '/program-dela/' || router.currentRoute.matched[0].path == '/program-dela')
                return 1;
            return 0;
        },

        sections_hidden() {
            //if no sections
            if(this.filteredSectionsByOrder.length == 0) return 1;

            //if at least one of sections ishidden, hide all
            for (let section of this.filteredSectionsByOrder) {
                if (section.hidden == 1)
                    return 1;
            }
            return 0;
        },

        toggle_hide_sections_text() {
            return (this.sections_hidden) == 0 ? 'Področja so prikazana uporabnikom' : 'Področja so skrita uporabnikom';
        },

        // Get current faculty
        getFaculty() {
            return this.$store.getters.getFaculty;
        },

        // Array of sections
        sections() {
            return this.$store.getters.sections;
        },

        // Array of images for sections
        sections_images() {
            return this.$store.getters.sections_images;
        },

        filteredSectionsByOrder: function () {
            let sections = Object.values(this.sections);
            return sections.sort((a, b) => (parseInt(a.order_number) > parseInt(b.order_number)) ? 1 : -1);
        },

        // Get loged user type
        getUserType() {
            return this.$store.getters.getUser.type;
        },

        // Get general sections intro
        get_sections_intro() {
            return this.$store.getters.sections_intro;
        },

        getSectionTabName() {
            return this.getTabCode == 0 ? '' : 'WorkPlan';
        },

        // Progress bar: Percentage of completed fields for section
        // Also, prepare object for checking weather and which sections are not filled up yet
        getSectionStatistics() {
            let sec_stat = this.$store.getters.sections_statistics;

            //---check and prepare object for submittion
            let sections = this.sections;

            //reset data
            this.not_filled_data = new Object();

            for (var sec in sec_stat) {
                let not_filled_m = sec_stat[sec]['stat_count_mandatory'] - sec_stat[sec]['stat_count_solved_mandatory'];
                if (not_filled_m > 0 && sections[sec]) {
                    this.not_filled_data[sec] = { name: sections[sec]['name'], not_filled_m: not_filled_m };
                }
            }
            //---end of check and prepare object for submittion

            return sec_stat;
        },

        allFilled(){
            let not_filled_m = sec_stat[sec]['stat_count_mandatory'] - sec_stat[sec]['stat_count_solved_mandatory'];
            return (not_filled_m === 0);
        },

        allSubmitted(){
            let stat = this.getSectionStatistics;
            //if at least one of sections is submitted
            for (let section_id in stat) {
                if (stat[section_id].submitted == 1) return true;
            }
            return false;
        }
    },
    methods: {
        ...mapActions([
            'prepareSections',
            'showModal',
            'getSectionsIntro',
            'prepareSectionsStat',
            'getFacultyAttachments',
            'exportSectionDataFacultiesPackage',
            'exportSectionDataAll',
            'exportSectionDataAllPackage',
            'getSectionsImages',
            'CopySectionsPreviousYear',
            'toggleHideSections'
        ]),

        //samo s paragrafi
        /* trimSectionDescription(description){
            const regex = /<p>(.*?)<\/p>/;
            const corresp = regex.exec(description);
            const firstParagraph = (corresp) ? corresp[0] : "" // <p>text1</p>
            const firstParagraphWithoutHtml = (corresp) ? corresp[1] : "" // text1
            const isLong = firstParagraphWithoutHtml.length > 150;
            let subs = firstParagraphWithoutHtml/* .replace(/<[^>]*>?/gm, '') *//*.substring(0,130);

            return subs + (isLong ? '...' : '');
        }, */

        //paragrafi in headerji
        trimSectionDescription(description) {
            const regex = /<(p|h[1-6])>(.*?)<\/(p|h[1-6])>/g;
            //const matches = description.match(regex);
            const match = regex.exec(description);

            if (!match) {
                return "";
            }

            /* const combinedText = matches.map(match => {
                const regexInner = /<(p|h[1-6])>(.*?)<\/(p|h[1-6])>/;
                const corresp = regexInner.exec(match);
                console.log(corresp);
                return (corresp && corresp[2]) ? corresp[2] : "";
            }).join('<br><br>'); */

            const content = match[2];

            const isLong = content.length > 135;
            let trimmedContent = content.substring(0, 135);

            return trimmedContent + (isLong ? '...' : '');
        },

        getImgUrl(image_id) {
            if (this.sections_images[image_id])
                return require('../../assets/img/section_images/' + this.sections_images[image_id].img_name);
        },

        // Format date to European standards
        getDeadlineDate: function (date) {
            return moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');
        },

        // Click on submit all sections
        submit_sections_all() {
            if (Object.keys(this.not_filled_data).length === 0)
                this.showModal({ component: 'sections/SubmitSections' });
            else
                this.showModal({ component: 'sections/SubmitSectionsNotFilled', data: this.not_filled_data });
        },

        // Click on repeal submit all sections
        repeal_submit_sections_all() {
            this.showModal({ component: 'sections/RepealSubmitSections' });
        }
    },
    created() {
        this.prepareSections();
        this.getSectionsIntro();
        this.prepareSectionsStat();
        this.getFacultyAttachments();
        this.getSectionsImages();
    },
    mounted() {

    }
}
</script>


<style lang="scss" scoped>

/* Transicije */
.fade-enter-active {
    transition: opacity 0.3s;
}

.fade-leave-active {
    transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

/* Sections images style */
img {
    width: 100%;
    height: 95%;
}
</style>
