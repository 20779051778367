<template>
    <div class="main_holder">
        <div class="content_holder">
            <div class="content sections_dashboard">
                <h2>Dashboard programov</h2>

                <div class="dashboard_tools">
                    <div class="dashboard_tools_top">
                        <div class="toolbar_select">
                            Program:
                            <select v-model="selected_program_id">
                                <option v-for="program in sortedPrograms" :value="program.id" :key="program.id">
                                    {{ program.name }} ({{ program.ul_program_id }})
                                </option>
                            </select>
                        </div>
                        <div class="toolbar_select">
                            Sklop:
                            <select v-model="selected_set">
                                <option v-for="set in sortedSets" v-bind:value="set.code" v-bind:key="set.code"
                                    :selected="selected_set == set.code">
                                    {{ set.name }}
                                </option>
                            </select>
                        </div>

                        <div class="dashboard_tools_stamp_filter" v-show="!isDoctorate">
                            Dodaj povprečje programov:
                            <div class="basic equal_margin" :class="{deadline_status_empty: !stamp_filter_active.averages.all, deadline_status: stamp_filter_active.averages.all}" 
                                @click="stamp_filter_active.averages.all = !stamp_filter_active.averages.all;">vseh članic</div>
                            <div class="basic equal_margin" :class="{deadline_status_empty: !stamp_filter_active.averages.faculty, deadline_status: stamp_filter_active.averages.faculty}" 
                                @click="stamp_filter_active.averages.faculty = !stamp_filter_active.averages.faculty;">{{getFacultyAcronym}}</div>
                            <div class="basic equal_margin" :class="{deadline_status_empty: !stamp_filter_active.averages.level, deadline_status: stamp_filter_active.averages.level}" 
                                @click="stamp_filter_active.averages.level = !stamp_filter_active.averages.level;">vseh članic iste stopnje</div>
                        </div>
                    </div>
                </div>

                <div class="evalvation_dashboard_graphs" v-if="programs_data_by_user[selected_program_id] &&
                evalvation_dashboard_values[programs_data_by_user[selected_program_id].ul_program_id]">
                    <div class="graphs_container"
                        v-if="evalvation_dashboard_values[programs_data_by_user[selected_program_id].ul_program_id][selected_set]">

                        <GraphDataset
                            v-if="evalvation_dashboard_values[programs_data_by_user[selected_program_id].ul_program_id][selected_set][component.code]"
                            v-for="component in sortedComponents" :key="component.id"
                            :dataset="generate_line_graph_data(component)"
                            :description="component.description"
                            :display_mode_bar="false">
                        </GraphDataset>
                    </div>
                    <div v-else class="dashboard_no_data">Za izbran sklop ni podatkov</div>
                </div>
                <div v-else class="dashboard_no_data">Za izbran program ni podatkov</div>
            </div>
        </div>
    </div>
</template>


<script>
import { mapActions } from "vuex";
import GraphDataset from './datasets/DashboardGraphData.vue';

export default {
    name: "EvalvationDashboard",
    components: {
        GraphDataset,
    },
    data: function () {
        return {
            selected_program_id: 0,
            selected_set: '',
            selected_component: '',
            stamp_filter_active: {
                averages:{
                    all: false,
                    faculty: false,
                    level: false
                }
            },
            overflow_percent: 0.07,
        };
    },
    computed: {
        year() {
            return this.$store.getters.year;
        },

        // Get loged user faculty id
        getFacultyId() {
            return this.$store.getters.getFaculty.id;
        },

        // Get loged user faculty acronym
        getFacultyAcronym() {
            return this.$store.getters.getFaculty.acronym;
        },

        // Get loged user type
        getUserType() {
            return this.$store.getters.getUser.type;
        },

        programs_data_by_user() {
            return this.$store.getters.evalvation_programs_user ? this.$store.getters.evalvation_programs_user : {};
        },

        evalvation_dashboard_sets() {
            return this.$store.getters.evalvation_dashboard_sets ? this.$store.getters.evalvation_dashboard_sets : {};
        },

        evalvation_dashboard_components() {
            return this.$store.getters.evalvation_dashboard_components ? this.$store.getters.evalvation_dashboard_components : {};
        },

        evalvation_dashboard_values() {
            return this.$store.getters.evalvation_dashboard_values ? this.$store.getters.evalvation_dashboard_values : {};
        },

        evalvation_dashboard_averages() {
            return this.$store.getters.evalvation_dashboard_averages ? this.$store.getters.evalvation_dashboard_averages : {};
        },

        // Sortiranje programov
        sortedPrograms: function () {
            let col = Intl.Collator("sl");
            let res = Object.values(this.programs_data_by_user).sort((a, b) => {
                return (col.compare(a.name, b.name) < 0) ? -1 : 1;
            });

            //set default selected program
            if (res[0])
                this.selected_program_id = res[0].id;

            return res;
        },

        // Sortiranje sklopov
        sortedSets: function () {
            let col = Intl.Collator("sl");
            let res = Object.values(this.evalvation_dashboard_sets).sort((a, b) => {
                return (col.compare(a.name, b.name) < 0) ? -1 : 1;
            });

            //set default selected set
            if (res[0])
                this.selected_set = res[0].code;

            return res;
        },

        isDoctorate: function () {
            let stopnja = (this.programs_data_by_user[this.selected_program_id] && 
                this.programs_data_by_user[this.selected_program_id].stopnja) ? 
                this.programs_data_by_user[this.selected_program_id].stopnja : '';
            return stopnja === 'tretja stopnja';
        },

        // Sortiranje components
        sortedComponents: function () {
            /* let col = Intl.Collator("sl");
            let res = Object.values(this.evalvation_dashboard_components).sort((a, b) => {
                return (col.compare(a.name, b.name) < 0) ? -1 : 1;
            }); */
            let res = Object.values(this.evalvation_dashboard_components);

            //set default selected component
            if (res[0])
                this.selected_component = res[0].code;

            return res;
        },
    },
    methods: {
        ...mapActions(["getEvalDashboard",
            'getProgramsListForUser',
            'getProgramsListForFaculty',]),

        programChange() {
            if(this.isDoctorate){
                this.stamp_filter_active.averages.level = false;
                this.stamp_filter_active.averages.faculty = false;
                this.stamp_filter_active.averages.all = false;
                this.selected_set = 'doctorate';
            }
            else{
                this.selected_set = 'after1';
            }
        },

        generate_line_graph_data(component) {
            // Local variables for each graph
            let fixed_range = true;
            let min_value = 0;
            let max_value = 0;

            // Determine if it is a doctorate program
            let data = this.isDoctorate
                ? this.generate_line_graph_data_dr(component)
                : this.generate_line_graph_data_normal(component);

            // Return both the data and the layout parameters
            return {
                data: this.isDoctorate ? data.data : data,
                fixed_range: this.isDoctorate ? data.fixed_range : fixed_range,
                min_value: this.isDoctorate ? data.min_value : min_value,
                max_value: this.isDoctorate ? data.max_value : max_value
            };
        },

        generate_line_graph_data_dr(component) {
            let data = [];
            let program_values = this.evalvation_dashboard_values[this.programs_data_by_user[this.selected_program_id].ul_program_id];

            let fixed_range = true;
            let min_value = 0;
            let max_value = 0;

            // A set to collect all unique years across all letniks
            let allYearsSet = new Set();
            let letnikMap = {};

            if (program_values) {
                // Iterate through all values
                for (let value_obj of program_values[this.selected_set][component.code]) {
                    let letnik = value_obj.letnik;
                    let year = value_obj.year;

                    // Add the year to the set of all unique years
                    allYearsSet.add(year);

                    // Initialize an object for the letnik if it doesn't exist
                    if (!letnikMap[letnik]) {
                        letnikMap[letnik] = {};
                    }

                    // Add the value for the corresponding letnik and year
                    letnikMap[letnik][year] = parseInt(value_obj.value) > 0 ? parseFloat(value_obj.value) : null;
                }

                // Convert the set of all years to a sorted array (ascending order)
                let allYears = Array.from(allYearsSet).sort((a, b) => a - b);

                // Sort letnik in descending order (assuming Roman numerals or similar)
                let sortedLetniks = Object.keys(letnikMap).sort((a, b) => b.localeCompare(a, undefined, { numeric: true }));

                // Generate Plotly data for each letnik in descending order
                for (let letnik of sortedLetniks) {
                    let x = [];
                    let y = [];

                    // Align each letnik's data to the full range of years
                    for (let year of allYears) {
                        // Always push the year to the x-axis
                        x.push(year + '/' + (parseInt(year) + 1));

                        // Add the corresponding value for the year, or null if not available
                        let letnik_value = letnikMap[letnik][year] || null;
                        y.push(letnik_value);

                        if (letnik_value !== null && parseInt(letnik_value) > 0) {
                            // Calculate min_value and max_value
                            if ((letnik_value - (letnik_value * this.overflow_percent)) < min_value || min_value === 0) {
                                min_value = letnik_value - (letnik_value * this.overflow_percent);
                            }

                            if (letnik_value > 5) {
                                fixed_range = false;

                                // Calculate max value
                                if ((letnik_value + (letnik_value * this.overflow_percent)) > max_value) {
                                    max_value = letnik_value + (letnik_value * this.overflow_percent);
                                }
                            }
                        }
                    }

                    // Add the series for the current letnik
                    data.push({
                        x: x,
                        y: y,
                        type: 'scatter',
                        name: letnik // Label the line with the letnik
                    });
                }
            }

            return {
                data: data,
                fixed_range: fixed_range,
                min_value: min_value,
                max_value: max_value
            };
        },

        generate_line_graph_data_normal(component) {
            let data = [];
            let program_values = this.evalvation_dashboard_values[this.programs_data_by_user[this.selected_program_id].ul_program_id];
            //array of years that will be ibind of all lines to program values
            let x_year = [];

            if (program_values) {
                let x = [];
                let y = [];

                for (let value_obj of program_values[this.selected_set][component.code]) {
                    if (parseInt(value_obj.value) > 0) {
                        let year_ext = value_obj.year + '/' + (parseInt(value_obj.year) + 1);
                        x.push(year_ext);
                        x_year.push(value_obj.year);
                        y.push(value_obj.value);
                    }
                }

                data.push({
                    x: x,
                    y: y,
                    //name: this.truncate(this.organizations[this.org_type][org_code].name),
                    //visible: this.org_type == 0 ? true : 'legendonly',
                    type: 'scatter',
                    name: 'program'
                });
            }

            if (this.stamp_filter_active.averages.all){
                let x = [];
                let y = [];

                let program_values = this.evalvation_dashboard_averages.all;
                for (let year of x_year) {
                    let value_obj = program_values[this.selected_set][component.code].find(obj => obj.year == year);
                    
                    if (parseInt(value_obj.value) > 0) {
                        let year_ext = value_obj.year + '/' + (parseInt(value_obj.year) + 1);
                        x.push(year_ext);
                        y.push(value_obj.value);
                    }
                }

                data.push({
                    x: x,
                    y: y,
                    type: 'scatter',
                    name: 'povprečje UL'
                });
            }

            if (this.stamp_filter_active.averages.faculty){
                let x = [];
                let y = [];

                let program_values = this.evalvation_dashboard_averages.faculty;
                for (let year of x_year) {
                    let value_obj = program_values[this.selected_set][component.code].find(obj => obj.year == year);

                    if (parseInt(value_obj.value) > 0) {
                        let year_ext = value_obj.year + '/' + (parseInt(value_obj.year) + 1);
                        x.push(year_ext);
                        y.push(value_obj.value);
                    }
                }

                data.push({
                    x: x,
                    y: y,
                    type: 'scatter',
                    name: 'povprečje '+this.getFacultyAcronym
                });
            }

            if (this.stamp_filter_active.averages.level){
                let x = [];
                let y = [];

                let program_values = this.evalvation_dashboard_averages.level[this.programs_data_by_user[this.selected_program_id].stopnja];
                for (let year of x_year) {
                    let value_obj = program_values[this.selected_set][component.code].find(obj => obj.year == year);

                    if (parseInt(value_obj.value) > 0) {
                        let year_ext = value_obj.year + '/' + (parseInt(value_obj.year) + 1);
                        x.push(year_ext);
                        y.push(value_obj.value);
                    }
                }

                data.push({
                    x: x,
                    y: y,
                    type: 'scatter',
                    name: 'povprečje stopnje'
                });
            }

            return data;
        },

        refresh_dashboard() {
            if (this.getUserType > 0)
                this.getProgramsListForFaculty();
            else
                this.getProgramsListForUser();

            this.getEvalDashboard();
        }
    },

    watch: {
        year(newVal, oldVal) {
            this.refresh_dashboard();
        },
        getFacultyId(newVal, oldVal) {
            this.refresh_dashboard();
        },
        selected_program_id(newVal, oldVal) {
            this.programChange();
        }
    },

    created() {
        this.refresh_dashboard();
    }
};
</script>


<style lang="scss" scoped>
@import "../../scss/style.scss";
</style>
