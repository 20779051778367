<template>
	<div class="content sections_dashboard">

		<div class="sections_upper_holder">
			<h2>Seznam programov za leto {{ year }}</h2>

			<div v-if="filteredRowByProgram.length > 0" class="submit_sections_buttons">
				<!-- Button for repealing submitting sections data -->
				<div class="button_holder submit_sections_data" v-if="getUserType == 2">
					<div v-if="all_submitted" class="button large" @click="repeal_submit_sections_all()">
						Razveljavi oddajo
					</div>
				</div>

				<!-- Button for submitting all programs data -->
				<div class="button_holder submit_sections_data"
					v-if="(getUserType == 1 && !all_disabled) || getUserType == 2">
					<div v-if="!all_submitted" class="button large" @click="submit_programs_all()">
						Oddaj
					</div>
					<div v-else class="button large disabled" title="Vsi programi so oddani">
						Oddano
					</div>
				</div>
			</div>
		</div>

		<div class="sections_intro_row">
			<div class="sections_intro">
				<!-- Not active year warning -->
				<div v-if="!isActiveYear" class="questionnaire_finished warning">
					<p>Izbrali ste študijsko leto, ki trenutno ni aktivno. Pri urejanju podatkov bodite še posebej previdni!
					</p>
				</div>

				<!-- Progress bar -->
					<div v-if="getUserType > 0" class="shell"
						v-bind:class="{ full: getFinishedStatistics.percentage == 100 }">
						<div class="bar"
							:style="{ width: (getFinishedStatistics.percentage ? getFinishedStatistics.percentage : 0) + '%' }">
						</div>
						<div class="percentage">
							{{ (getFinishedStatistics.percentage ? getFinishedStatistics.percentage : 0) }}% ({{
								getFinishedStatistics.cntFinished }}/{{ getFinishedStatistics.cntAll }})
						</div>
					</div>

					<ul-sections-list-upload class="" v-if="getUserType == 2 || getUserType == 1" :upload_type="1" :tab="2"></ul-sections-list-upload>

				<!-- Progress bar -->
			</div>
			<div class="file_exports">
				<div v-if="getUserType > 0" class="as_link icon" title="Izvoz vseh programov"
					@click="exportAllProgramsPackage({ format: 'doc' })">
					<font-awesome-icon :icon="['far', 'file-archive']" />
				</div>
			</div>
		</div>

		<div class="dashboard_tools">
			<div class="dashboard_tools_top">
				<div class="dashboard_search_bar">
					<!-- Dashboard Search bar -->
					<input type="text" v-model="search" placeholder="Išči" @keydown="currentPage = 1;" />
				</div>
			</div>

			<div class="dashboard_tools_stamp_filter_group">
				<div v-if="getUserType > 0" class="dashboard_tools_stamp_filter in_group">
					<div class="filled"
						:class="{ deadline_status_empty: !stamp_filter_active.finished.filled, deadline_status: stamp_filter_active.finished.filled }"
						@click="stamp_filter_active.finished.filled = !stamp_filter_active.finished.filled;">zaključeno
					</div>
					<div class="after_late"
						:class="{ deadline_status_empty: !stamp_filter_active.finished.after_late, deadline_status: stamp_filter_active.finished.after_late }"
						@click="stamp_filter_active.finished.after_late = !stamp_filter_active.finished.after_late;">
						nezaključeno</div>
				</div>
				<div v-if="getUserType > 0" class="dashboard_tools_stamp_filter in_group">
					<div class="basic"
						:class="{ deadline_status_empty: !stamp_filter_active.degree.first, deadline_status: stamp_filter_active.degree.first }"
						@click="stamp_filter_active.degree.first = !stamp_filter_active.degree.first;">prva</div>
					<div class="basic"
						:class="{ deadline_status_empty: !stamp_filter_active.degree.second, deadline_status: stamp_filter_active.degree.second }"
						@click="stamp_filter_active.degree.second = !stamp_filter_active.degree.second;">druga</div>
					<div class="basic"
						:class="{ deadline_status_empty: !stamp_filter_active.degree.third, deadline_status: stamp_filter_active.degree.third }"
						@click="stamp_filter_active.degree.third = !stamp_filter_active.degree.third;">tretja</div>
					<div class="basic"
						:class="{ deadline_status_empty: !stamp_filter_active.degree.training, deadline_status: stamp_filter_active.degree.training }"
						@click="stamp_filter_active.degree.training = !stamp_filter_active.degree.training;">izpopolnjevanje
					</div>
				</div>
				<div v-if="getUserType > 0" class="dashboard_tools_stamp_filter in_group">
					<div class="preparation_late"
						:class="{ deadline_status_empty: !stamp_filter_active.mode.full, deadline_status: stamp_filter_active.mode.full }"
						@click="stamp_filter_active.mode.full = !stamp_filter_active.mode.full;">redni</div>
					<div class="preparation_late"
						:class="{ deadline_status_empty: !stamp_filter_active.mode.part, deadline_status: stamp_filter_active.mode.part }"
						@click="stamp_filter_active.mode.part = !stamp_filter_active.mode.part;">izredni</div>
					<div class="preparation_late"
						:class="{ deadline_status_empty: !stamp_filter_active.mode.other, deadline_status: stamp_filter_active.mode.other }"
						@click="stamp_filter_active.mode.other = !stamp_filter_active.mode.other;">drugo</div>
				</div>
			</div>
		</div>

		<div class="table-wrapper user_list">
			<table class="table table-striped table-hover">
				<thead>
					<tr>
						<th> </th>
						<th @click="sort('name')">
							<font-awesome-icon :icon="['fas', 'sort']" />
							Program
						</th>
						<th @click="sort('ul_program_id')">
							<font-awesome-icon :icon="['fas', 'sort']" /> Šifrant
						</th>
						<th @click="sort('description')">
							<font-awesome-icon :icon="['fas', 'sort']" /> Opis
						</th>
						<th @click="sort('stopnja')">
							<font-awesome-icon :icon="['fas', 'sort']" />
							Stopnja
						</th>
						<th @click="sort('vrsta')">
							<font-awesome-icon :icon="['fas', 'sort']" /> Vrsta
						</th>
						<th @click="sort('nacin_studija')">
							<font-awesome-icon :icon="['fas', 'sort']" /> Način
							študija
						</th>
						<!--<th @click="sort('akreditacija_od')">
                            <font-awesome-icon :icon="['fas', 'sort']" />
                            Akreditacija
                        </th>-->
						<th @click="sort('finished')">
							<font-awesome-icon :icon="['fas', 'sort']" /> Zaključeno
						</th>
					</tr>
				</thead>

				<tbody>
					<tr :class="{ redundant: (dash_program.redundant == 1 && getUserType == 2) }" :key="dash_program.id"
						v-for="dash_program in sortedPrograms">
						<td><input type="checkbox"
								:disabled="dash_program.finished == 1 || dash_program.disabled == 1 || dash_program.stopnja == 'tretja stopnja'"
								@change="updateMultipleFill(dash_program.id, $event)" :checked="isChecked(dash_program.id)">
						</td>
						<router-link tag="td" class="pointer"
							:to="{ name: 'programDetail', params: { programs: dash_program.id, q_type: /*dash_program.stopnja == 'tretja stopnja' && year > '2019/2020' ? 4 :*/ 1 } }">{{
								dash_program.name }}</router-link>
						<router-link tag="td" class="pointer"
							:to="{ name: 'programDetail', params: { programs: dash_program.id, q_type: /*dash_program.stopnja == 'tretja stopnja' && year > '2019/2020' ? 4 :*/ 1 } }">{{
								dash_program.ul_program_id }}</router-link>
						<router-link tag="td" class="pointer"
							:to="{ name: 'programDetail', params: { programs: dash_program.id, q_type: /*dash_program.stopnja == 'tretja stopnja' && year > '2019/2020' ? 4 :*/ 1 } }">{{
								dash_program.description }}</router-link>
						<router-link tag="td" class="pointer"
							:to="{ name: 'programDetail', params: { programs: dash_program.id, q_type: /*dash_program.stopnja == 'tretja stopnja' && year > '2019/2020' ? 4 :*/ 1 } }">{{
								dash_program.stopnja }}</router-link>
						<router-link tag="td" class="pointer"
							:to="{ name: 'programDetail', params: { programs: dash_program.id, q_type: /*dash_program.stopnja == 'tretja stopnja' && year > '2019/2020' ? 4 :*/ 1 } }">{{
								dash_program.vrsta }}</router-link>
						<router-link tag="td" class="pointer"
							:to="{ name: 'programDetail', params: { programs: dash_program.id, q_type: /*dash_program.stopnja == 'tretja stopnja' && year > '2019/2020' ? 4 :*/ 1 } }">{{
								dash_program.nacin_studija }}</router-link>
						<router-link tag="td" class="pointer align_center"
							:to="{ name: 'programDetail', params: { programs: dash_program.id, q_type: /*dash_program.stopnja == 'tretja stopnja' && year > '2019/2020' ? 4 :*/ 1 } }">
							<font-awesome-icon v-if="dash_program.finished == 1" :icon="['fas', 'check']" /></router-link>
						<!--<td>{{dash_program.akreditacija_od}}</td>-->
					</tr>

					<!-- pagination -->
					<tr v-show="maxPage > 1">
						<td colspan="7">
							<ul-pagination-row :maxPage="maxPage" :currentPage="currentPage"
								@change_page="set_current_page"></ul-pagination-row>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="fill_multiple_holder">
			<!--<router-link tag="button" class="fill_multiple button" :class="disableMultipleFillButtonClass" title="Izpolni skupaj" :disabled="disableMultipleFill" 
				:to="{ name: 'programDetail', params: {programs: multiple_programs_string} }">
					Izpolni skupaj
			</router-link>-->
			<button class="fill_multiple button" :class="disableMultipleFillButtonClass" title="Izpolni skupaj"
				:disabled="disableMultipleFill" @click="onMultipleFillClicked()">
				Izpolni skupaj
			</button>
		</div>

	</div>
</template>


<script>
import { mapActions } from 'vuex';
import PaginationRow from '../general/PaginationRow.vue';
import SectionsListUpload from './../sections/SectionsListUpload.vue';

export default {
	name: 'EvalvationPrograms',
	components: {
		'ul-pagination-row': PaginationRow,
		'ul-sections-list-upload': SectionsListUpload,
	},
	data: function () {
		return {
			search: "",
			currentSort: "name",
			currentSortDir: "asc",
			stamp_filter_active: {
				finished: {
					filled: false,
					after_late: false,
				},
				degree: {
					first: false,
					second: false,
					third: false,
					training: false,
				},
				mode: {
					other: false,
					full: false,
					part: false,
				}
			},
			currentPage: 1,
			perPage: 12,
			maxPage: 1,
		}
	},
	computed: {
		year() {
			return this.$store.getters.year;
		},

		// Get loged user type
		isActiveYear() {
			return this.$store.getters.is_active_year;
		},

		programs_data_by_user() {
			let data = this.$store.getters.evalvation_programs_user ? this.$store.getters.evalvation_programs_user : {};
			return Object.values(data);
		},
		filteredRowByProgram: function () {
			return this.programs_data_by_user.filter(data => {
				if (this.getUserType != 2 && data.hidden == 1)
					return false;

				//Stamps filter
				if (!(Object.values(this.stamp_filter_active.finished).every(Boolean) || (this.stamp_filter_active.finished.filled === false &&
					this.stamp_filter_active.finished.after_late === false))) {

					if ((this.stamp_filter_active.finished.filled && data.finished == 0) ||
						(this.stamp_filter_active.finished.after_late && data.finished == 1))
						return false;
				}

				if (!(Object.values(this.stamp_filter_active.degree).every(Boolean) || (this.stamp_filter_active.degree.first === false
					&& this.stamp_filter_active.degree.second === false && this.stamp_filter_active.degree.third === false
					&& this.stamp_filter_active.degree.training === false))) {

					if ((!this.stamp_filter_active.degree.first && data.stopnja.includes('prva'))
						|| (!this.stamp_filter_active.degree.second && data.stopnja.includes('druga'))
						|| (!this.stamp_filter_active.degree.third && data.stopnja.includes('tretja'))
						|| (!this.stamp_filter_active.degree.training && data.stopnja.includes('izpopolnjevanje')))
						return false;
				}

				if (!(Object.values(this.stamp_filter_active.mode).every(Boolean) || (this.stamp_filter_active.mode.other === false
					&& this.stamp_filter_active.mode.full === false && this.stamp_filter_active.mode.part === false))) {

					if (!((this.stamp_filter_active.mode.full && (data.nacin_studija.includes('redni,') || data.nacin_studija == 'redni'))
						|| (this.stamp_filter_active.mode.part && data.nacin_studija.includes('izredni')
							|| (this.stamp_filter_active.mode.other && !data.nacin_studija.includes('redni')))))
						return false;
				}

				let faculty_data = this.getFaculties[data.faculty_id];
				let filter_string = '';

				//filter
				if (faculty_data) {
					filter_string = faculty_data['acronym'] + ' ' + faculty_data['name'] + ' ' + data['name'] + ' ' + data['stopnja'] + ' ' +
						data['vrsta'] + ' ' + data['nacin_studija'] + ' ' + data['ul_program_id'] +/*' '+data['akreditacija_od']+*/' ' + data['description'] + ' ' + data['stopnja'] + '. stopnja';
				}
				if (filter_string.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) {
					return true;
				}
				return false;
			});
		},

		//check if all prorgams are submitted
		all_submitted() {
			for (let program of this.programs_data_by_user) {
				if (program.submitted == 0)
					return false;
			}
			return true;
		},

		//check if all prorgams are hidden or disabled
		all_disabled() {
			for (let program of this.programs_data_by_user) {
				if (program.hidden == 0 && program.disabled == 0)
					return false;
			}
			return true;
		},

		//check if all prorgams are finished
		all_finished() {
			for (let program of this.programs_data_by_user) {
				if (program.finished == 0)
					return false;
			}
			return true;
		},

		// Get all faculties
		getFaculties() {
			return this.$store.getters.getFaculties;
		},

		// Get array of multiple fill
		getMultipleFill() {
			return this.$store.getters.evalvation_programs_multiple_fill;
		},

		//Get statistics of filled programs
		getFinishedStatistics() {
			let cntFinished = 0;
			for (let program of this.programs_data_by_user) {
				if (program.finished == 1)
					cntFinished++;
			}
			return { cntAll: this.programs_data_by_user.length, cntFinished: cntFinished, percentage: Math.round(cntFinished / this.programs_data_by_user.length * 100) }
		},

		//Do we disable routing for multiple fill
		disableMultipleFill() {
			return this.getMultipleFill.length < 2;
		},
		//Do we make button for multiple fill disabled
		disableMultipleFillButtonClass() {
			return this.disableMultipleFill ? 'disabled' : '';
		},

		multiple_programs_string() {
			return this.getMultipleFill.join(',');
		},

		// Get loged user type
		getUserType() {
			return this.$store.getters.getUser.type;
		},

		// Sortiranje tabele uporabnikov
		sortedPrograms: function () {
			let col = Intl.Collator("sl");
			let res = this.filteredRowByProgram.sort((a, b) => {
				let modifier = 1;
				if (this.currentSortDir === "desc") modifier = -1;
				if (col.compare(a[this.currentSort], b[this.currentSort]) < 0)
					return -1 * modifier;
				if (col.compare(a[this.currentSort], b[this.currentSort]) > 0)
					return 1 * modifier;
				return 0;
			});

			//Calculate number of all pages
			this.maxPage = Math.ceil(res.length / this.perPage);
			//Return only items on given page
			return res.slice((this.currentPage * this.perPage) - this.perPage, this.currentPage * this.perPage);
		},

	},
	methods: {
		...mapActions([
			'getProgramsListForUser',
			'getProgramsListForFaculty',
			'checkMultiprogramData',
			'resetProgramsMultipleFill',
			'setProgramsMultipleFill',
			'exportAllProgramsPackage',
			'showModal',
			'getFacultyAttachments'
		]),

		//Change current page
		set_current_page(page) {
			this.currentPage = page;
		},

		// Click on submit all programs
		submit_programs_all() {
			this.showModal({ component: 'evalvation/SubmitPrograms', data: { allFinished: this.all_finished } });
		},

		// Click on repeal submit all sections
		repeal_submit_sections_all() {
			this.showModal({ component: 'evalvation/RepealSubmitPrograms' });
		},

		onMultipleFillClicked() {
			this.checkMultiprogramData();
		},

		//update array for multiple fill
		updateMultipleFill(id, event) {
			this.setProgramsMultipleFill({ id: id, add: event.target.checked });
		},

		//is this program checked in multiple
		isChecked(id) {
			return this.getMultipleFill.includes(id);
		},

		/*sortBy: function (sortKey) {
			this.reverse = this.sortKey == sortKey ? !this.reverse : false;

			this.sortKey = sortKey;
		},*/

		sort: function (s) {
			//reset array for multiple fill
			//this.resetProgramsMultipleFill();

			//if s == current sort, reverse
			if (s === this.currentSort) {
				this.currentSortDir =
					this.currentSortDir === "asc" ? "desc" : "asc";
			}
			this.currentSort = s;
		},
	},
	watch: {
		filteredRowByProgram(newVal, oldVal) {
			this.set_current_page(1);
		}
	},
	created() {
		//if(this.programs_data_by_user.length == 0){
		if (this.getUserType > 0)
			this.getProgramsListForFaculty();
		else
			this.getProgramsListForUser();
		//}

		//reset array for multiple fill
		this.resetProgramsMultipleFill();
		this.getFacultyAttachments();
	},
}
</script>

