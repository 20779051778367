<template>
    <div class="evalvation_question_table_holder question" :class="{locked_question: locked_input}">

        <br v-if="!disable_input || build_mode"> 
        <!-- Table -->
        <div class="evalvation_question_table">
            <div v-if="!disable_input && Object.keys(question_columns).length > 0"
                    class="questoion_table_holder">
                <div v-if="!build_mode && ((program_data.year == '2020/2021' && program_data.skippable == 1) || 
                        (question_detail.id_last_year !== null && question_data.table_rows_last_year)) 
                        && (!question_data.table_rows || Object.keys(question_data.table_rows).length == 0)
                        && question_detail.skippable == 1 && ((program_data.finished == 0 && program_data.submitted == 0 && program_data.disabled == 0) || getUserType == 2)"
                    class="as_link icon unlock_question_link unlock" title="Zakleni vprašanje" @click="lockQuestion">
                    <font-awesome-icon :icon="['fas', 'unlock']" />
                </div>
            </div>

            <!-- Table header row -->
            <div v-if="build_mode || (!disable_input && Object.keys(question_columns).length > 0)" class="row header">
                <!-- Table columns -->
                <div class="column column_header" v-for="column in question_columns" :key="column.id">

                    <span class="question_title_row">
                        <span v-if="build_mode && (getUserType == 2 || (getUserType == 1 && (is_internal_questionnaire || (q_type != 1 && q_type != 4))))" class="as_link icon" title="Uredi stolpec">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']" @click="showModal({component: 'evalvation/QuestionEditColumnTable', 
                                data:{name:column.name, description:column.description, column_id:column.id, q_order_num: question_order_num, c_order_num:column.order_number}})" />
                        </span>

                        <span v-if="build_mode && (getUserType == 2|| (getUserType == 1 && (is_internal_questionnaire || (q_type != 1 && q_type != 4))))" class="as_link icon" title="Pobriši stolpec">
                            <font-awesome-icon :icon="['far', 'trash-alt']" @click="showModal({component: 'evalvation/QuestionDeleteColumnTable', 
                                data:{column_id:column.id, c_order_num:column.order_number, q_order_num: question_order_num}})" />
                        </span>

                        <span class="title">
                            {{ column.name }}
                        </span>
                    </span>

                    <span class="description">
                        {{ column.description }}
                    </span>

                </div>

                <!-- Add column -->
                <div v-if="build_mode && (getUserType == 2 || (getUserType == 1 && (is_internal_questionnaire || (q_type != 1 && q_type != 4))))" class="column add-column" title="Dodaj stolpec" @click="showModal({component: 'evalvation/QuestionCreateColumnTable', 
                    data:{question_id: question_detail.id, order_number:Object.keys(question_columns).length+1}})">
                    <div class="as_link icon">
                        <font-awesome-icon :icon="['far', 'plus-square']" /> Dodaj stolpec
                    </div>
                </div>

                <!-- Tools table columns -->
                <div class="column tools" v-if="!build_mode && Object.keys(question_columns).length > 0">
                </div>
            </div>

            <!-- Table data rows -->
            <div v-if="!build_mode">
                <div class="row data" v-for="(row, row_id) in question_data.table_rows" :key="row_id">
                    <!-- Table columns -->
                    <div class="column" v-for="col in question_columns" :key="col.id">
                        <div>
                            <textarea rows="4" :disabled="disable_input" v-model="question_data.table_rows[row_id].table_columns[col.id]" @change="cellChanged(row_id, col.id, $event)">  
                            </textarea>
                        </div>
                    </div>

                    <!-- Tools table columns - delete column -->
                    <div class="column tools">
                        <div class="as_link icon" v-if="!disable_input" title="Pobriši vrstico">
                            <font-awesome-icon :icon="['far', 'trash-alt']" @click="showModal({component: 'evalvation/QuestionDeleteRowTable', data:{row_id:row_id, question_id:question_detail.id, q_type:q_type}})" />
                        </div>
                    </div>
                </div>

                <!-- <div v-if=" 
                        (!question_data.table_rows || Object.keys(question_data.table_rows).length == 0)">
                    <div v-if="!question_data.table_rows || Object.keys(question_data.table_rows).length == 0"
                        class="as_link icon unlock_question_link" title="Zakleni vprašanje" @click="lockQuestion">
                        <font-awesome-icon :icon="['fas', 'unlock']" />
                    </div>
                    <div class="row add-data">
                        <div class="as_link icon" title="Dodaj vrstico" @click="addTableRow()">
                            <font-awesome-icon :icon="['far', 'plus-square']" /> Dodaj vrstico
                        </div>
                    </div>
                </div>

                <div v-else-if="program_data.skippable == 1 && question_detail.skippable == 1 && !build_mode">
                    <div class="questoion_textarea_holder" >
                        <textarea :class="textarea_sample" 
                            type="text" rows="4" :disabled="disable_input" 
                            :placeholder="'Polje ste izpolnili že prejšnje leto. \nTo polje se izpolni vsaki dve leti pod pogojem, da se sestavine študijskega programa niso spremenile. V primeru sprememb sestavin programa ali bistvenih odstopanj v trendih podatkov polje odklenite in ga izpolnite.'">
                        </textarea>
                        <div v-if="locked_input && !force_unlock" class="as_link icon unlock_question_link table" title="Odkleni vprašanje" @click="unlockQuestion()">
                            <font-awesome-icon :icon="['fas', 'lock']" />
                        </div>
                    </div>
                    <div class="row add-data" >
                        <div class="icon" title="Dodaj vrstico">
                            <font-awesome-icon :icon="['far', 'plus-square']" /> Dodaj vrstico
                        </div>
                    </div>
                </div> -->

                <div v-if="!disable_input && Object.keys(question_columns).length > 0">
                    <!-- Table data - add row -->
                    <div class="row add-data">
                        <div class="as_link icon" title="Dodaj vrstico" @click="addTableRow()">
                            <font-awesome-icon :icon="['far', 'plus-square']" /> Dodaj vrstico
                        </div>
                    </div>
                </div>
                
                <div v-else-if="program_data.year == '2020/2021' && (Object.keys(question_columns).length > 0 && 
                        (!question_data || !question_data.table_rows || Object.keys(question_data.table_rows).length == 0))">
                    <div class="questoion_textarea_holder" >
                        <textarea :class="textarea_sample" 
                            type="text" rows="4" :disabled="disable_input" 
                            :placeholder="'Polje ste izpolnili že prejšnje leto. \nTo polje se izpolni vsaki dve leti pod pogojem, da se sestavine študijskega programa niso spremenile. V primeru sprememb sestavin programa ali bistvenih odstopanj v trendih podatkov polje odklenite in ga izpolnite.'">
                        </textarea>
                        <div v-if="locked_input && !force_unlock" class="as_link icon unlock_question_link table" title="Odkleni vprašanje" @click="unlockQuestion()">
                            <font-awesome-icon :icon="['fas', 'lock']" />
                        </div>
                    </div>
                    <div class="row add-data" >
                        <div class="icon" title="Dodaj vrstico">
                            <font-awesome-icon :icon="['far', 'plus-square']" /> Dodaj vrstico
                        </div>
                    </div>
                </div>

                <div v-else-if="question_data && (!question_data.table_rows || (question_data.table_rows_last_year && Object.keys(question_data.table_rows_last_year).length == 0)) 
                        && question_data.table_rows_last_year && Object.keys(question_data.table_rows_last_year).length > 0"
                        class="questoion_table_holder">

                    <div v-if="locked_input && !force_unlock && ((program_data.finished == 0 && program_data.submitted == 0 && program_data.disabled == 0) || getUserType == 2)" 
                            class="as_link icon unlock_question_link table" title="Odkleni vprašanje" @click="unlockQuestion()">
                        <font-awesome-icon :icon="['fas', 'lock']" />
                    </div>

                    <div class="row data" v-for="(row, row_id) in question_data.table_rows_last_year" :key="row_id">
                        <!-- Table columns -->
                        <div class="column" v-for="col in question_columns" :key="col.id">
                            <div>
                                <textarea class="resize" rows="4" :disabled="disable_input" v-model="question_data.table_rows_last_year[row_id].table_columns[col.id_last_year]">  
                                </textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else class="row data" v-for="(row, key) in {1:{}, 2:{}}" :key="key">    
                <div class="column" v-for="column in question_columns" :key="column.id">
                    <div>
                        <textarea rows="4" disabled></textarea>
                    </div>
                </div>
                <div class="column add-column"></div>
            </div>
        </div>
    </div>
</template>


<script>
	import { mapActions } from 'vuex';
    
	export default {
		name: 'EvalvationQuestionnaireQuestionTable',
		components: {
        },
        data: function() {
			return {
                force_unlock: false
			}
        },
        props: {
            build_mode: {
                type: Boolean,
                required: true
            },
            question_order_num: {
                type: [String, Number],
                required: true
            },
            q_type: {
                type: [String, Number],
                required: true
            }
        },
        computed: {
			// Selected questions in active page
			question_detail(){
                if(this.active_page_id && this.active_page_id !== 'RU' && this.$store.getters.evalvation_questionnaire_detail.pages){
                    if(this.$store.getters.evalvation_questionnaire_detail.pages[this.active_page_id])
                        return this.$store.getters.evalvation_questionnaire_detail.pages[this.active_page_id].questions[this.question_order_num];
                    else
                        return this.$store.getters.evalvation_internal_questionnaire_detail.pages[this.active_page_id].questions[this.question_order_num];
                }
                return null;
            },

            question_columns(){
                if(this.question_detail.columns){
                    return this.question_detail.columns;
                }
                return {};
            },

            //get IDs of all programs for this questionnaire
            programs_id(){
                if(this.q_type == 1 || this.q_type == 4)
                    return this.$route.params.programs.toString().split(",");
                else
                    return this.$route.params.department;
            },

            //id of active page
            active_page_id(){
                return this.$store.getters.active_q_page_id;
            },
            //get answers for this questionnaire
            question_data(){
                if(this.build_mode || !this.$store.getters.questionnaire_data[this.question_detail.id])
                    return {};
                else {
                    return this.$store.getters.questionnaire_data[this.question_detail.id];
                }
            },
            // Get loged user type
			getUserType(){
				return this.$store.getters.getUser.type;
            },
            is_internal_questionnaire(){
                return this.active_page_id && this.$store.getters.evalvation_internal_questionnaire_detail.pages && this.$store.getters.evalvation_internal_questionnaire_detail.pages[this.active_page_id];
            },

            // Get program data
            program_data(){
                if(this.build_mode)
                    return {};

                //if user is admin, pull program data from admins program object
                if(this.programs_id.length > 0 || Number.isInteger(this.programs_id)){
                    return this.$store.getters.evalvation_program_detail;
                }
            },

            /* disable_input(){
                return !this.is_internal_questionnaire && (this.q_type == 1 || this.q_type == 4) && 
                    (this.build_mode || (this.getUserType == 0 && (this.program_data.finished == 1 || this.program_data.submitted == 1 || this.program_data.disabled == 1)));
            }, */

            disable_input(){
                return this.getUserType == 3 || ((this.locked_input && !this.force_unlock) || this.build_mode || 
                (this.getUserType < 2 && !this.is_internal_questionnaire && 
                (this.q_type == 1 || this.q_type == 4) && 
                (this.program_data.finished == 1 || this.program_data.submitted == 1 || this.program_data.disabled == 1)));
            },

            locked_input(){
                if(!this.build_mode){
                    //if question is table and already have data inside
                    if(this.question_data.table_rows && Object.keys(this.question_data.table_rows).length > 0)
                        return false;

                    //for first year, before connected questions
                    if(this.program_data.year == '2020/2021'){
                        let programs_skippable = 1;

                        //if single program
                        if(this.programs_id.length == 1)
                            programs_skippable = this.program_data.skippable;

                        //if multiple programs
                        else{
                            //if main program is skippable on 0, end here
                            if(this.program_data.skippable == 0)
                                programs_skippable = 0;
                            //go trough additional programs
                            else
                                for(let pid in this.program_data.additional_programs){
                                    if(this.program_data.additional_programs[pid].skippable == 0){
                                        programs_skippable = 0;
                                        break;
                                    }
                                }
                        }

                        // both, programs and question has to be skippable
                        if(this.question_detail.skippable == 1 && programs_skippable == 1){
                            if(!this.question_data || Object.keys(this.question_data).length == 0)
                                return true;
                            else  
                                return (this.question_data.table_rows == undefined || Object.keys(this.question_data.table_rows).length == 0);
                        }
                    }
                    //after 2020/2021 questions are connected, so no need to skippable programs
                    else{
                        if(this.question_detail.skippable == 1 && this.question_data.table_rows_last_year 
                                && Object.keys(this.question_data.table_rows_last_year).length > 0){
                            return true;
                        }
                    } 
                }
                return false;
            },

            //class for sample or input
            textarea_sample(){
                if(this.build_mode)
                    return 'textarea_sample';
                return '';
            },
        }, 
        methods: { 		
            ...mapActions([
                'showModal',
                'addQuestionTableRow',
                'saveQuestionTableCell'
            ]),

            // When cell is changed
            cellChanged(row_id, column_id, event){
                let newValue = event.target.value;
                this.saveQuestionTableCell({row_id:row_id, column_id:column_id, value:newValue, questionnaire_type:this.q_type, 
                    additional_programs: Array.from(this.programs_id).slice(1)});
            },

            //add row to table
            addTableRow(){
                this.addQuestionTableRow({dep_or_prog_id:this.programs_id, question_id:this.question_detail.id, q_order_num: this.question_detail.order_number, 
                    questionnaire_type:this.q_type, additional_programs: Array.from(this.programs_id).slice(1)});
            },

            unlockQuestion(){
                this.force_unlock = true;
                this.$emit('unlock_clicked');
            },

            lockQuestion(event){
                this.force_unlock = false;
                this.$emit('lock_clicked');
            },
        },
	}
</script>


<style lang="scss" scoped>
	@import "../../scss/style.scss";

	/* Transicije */
	.fade-enter-active{
		transition: opacity 0.3s;
	}
	.fade-leave-active{
		transition: opacity 0.2s;
	}
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
	}
</style>
