import axios from 'axios'
import * as general_mutations from '../mutations';
import * as general_state from '../store';
import router from '../../router/router'
import moment from 'moment';

const state = {
    // User data (name, surname, email): user management for data table
	users_list_data: {},
	
	// All users
	added_users: {},

	// Deleted users
	deleted_users: {},

	// FAQ text
	faq_text: "",

	// Notices
	notices: {}
};


const getters = {
	// Return list of users 
	users_list_data: store => {
		return state.users_list_data;
	},
	
	// Return list of new users 
	new_users_list_data: store => {
		return state.added_users;
	},

	// Return list of deleted users 
	deleted_users_list_data: store => {
		return state.deleted_users;
	},

	// Returm faq text
	faq_text: store => {
		return state.faq_text;
	},

	// Returm notices
	notices: store => {
		return state.notices;
	},
};


const mutations = {

    // Prepare list of all sections for user
	setUserList: (state, payload) => {
		state.users_list_data = payload;
	},

	// Prepare list of all added users
	addUser: (state, payload) => {
		state.users_list_data[payload.id] = payload;
		// Force refresh - VUE SET...?
		var temp = state.users_list_data;
		state.users_list_data = {};
		state.users_list_data = temp;
	},

	// Remove subsection from array
	deleteUser: (state, payload) => {
		let users_id = [];

		//create array of one element if single delete
		if(!Array.isArray(payload))
			users_id.push(payload);
		else
			users_id = payload;

		//delete multiple users
		for(let id of users_id){
			delete state.users_list_data[id];
		}

		// Force refresh - VUE SET...?
		var temp = state.users_list_data;
		state.users_list_data = {};
		state.users_list_data = temp;
	},

	// Prepare list of all added users
	editUser: (state, payload) => {
		//If we are editing user in user profile (self)
		if(payload.id == general_state.state.user.id){
			general_state.state.user.name = payload.name;
			general_state.state.user.surname = payload.surname;
			general_state.state.user.email = payload.email;
			general_state.state.user.notifications = payload.notifications;
		}

		state.users_list_data[payload.id] = payload;
		// Force refresh - VUE SET...?
		var temp = state.users_list_data;
		state.users_list_data = {};
		state.users_list_data = temp;
	},

	// Set access list for programs
	editUserPrograms: (state, payload) => {
		state.users_list_data[payload.id]['access_program_ids'] = payload.program_ids;
	},

	// Set access list for departments
	editUserDepartments: (state, payload) => {
		state.users_list_data[payload.id]['access_oddelki_ids'] = payload.oddelki_ids;
	},

	getFaq: (state, payload) => {
		state.faq_text = payload;
	},

	getNotices: (state, payload) => {
		state.notices = payload;
	},

	editNotice: (state, payload) => {
		state.notices[payload.id].text = payload.text;

		// Force refresh - VUE SET...?
		var temp = state.notices;
		state.notices = {};
		state.notices = temp;
	},

	addNotice: (state, payload) => {
		state.notices[payload.id] = {};
		state.notices[payload.id].id = payload.id;
		state.notices[payload.id].tab = payload.tab;
		state.notices[payload.id].text = '';
		state.notices[payload.id].created = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
		state.notices[payload.id].last_updated = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');

		// Force refresh - VUE SET...?
		var temp = state.notices;
		state.notices = {};
		state.notices = temp;
	},

	deleteNotice: (state, payload) => {
		delete state.notices[payload.id];

		// Force refresh - VUE SET...?
		var temp = state.notices;
		state.notices = {};
		state.notices = temp;
	},

	changeUserTypeTest: (state, payload) => {
		general_state.state.user.type = payload;
	}
};


const actions = {
	// Get users data for user management (userList): data table
	getUserList: ({commit}) => {
		general_mutations.showModalWorking(general_state.state);
		axios.get('?module=user&action=get_user_list')
		.then(
			res => {
				if(!res.data){				
					var error = res.data.error ? res.data.error : "Napaka";
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
				}
				else{
					commit('setUserList', res.data);
					general_mutations.hideModalWorking(general_state.state);
				}
			}
		)
		.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},
	
	// Get users data for user management for a faculty
	getUserListByFaculty: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.get('?module=user&action=get_user_list_faculty&faculty_id='+general_state.state.faculty.id)
		.then(
			res => {
				if(!res.data){				
					var error = res.data.error ? res.data.error : "Napaka";
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
				}
				else{
					commit('setUserList', res.data);
					general_mutations.hideModalWorking(general_state.state);
				}
			}
		)
		.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
    },
    
	// Add New users data for user management (userList): data table
	addUser: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=user&action=add_user', payload )
		.then(
			res => {
				if(!res.data.success){				
					var error = res.data.error ? res.data.error : "Napaka";
					general_mutations.hideModalWorking(general_state.state);

					if(error.includes("Duplicate") && error.includes("email"))
						error = "Uporabnik s tem elektronskim naslovom že obstaja!"
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
				}
				else{
                    payload.id = res.data.user_id;
					commit('addUser', payload);
					general_mutations.hideModalWorking(general_state.state);
				}
			}
		)
		.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},
	
	// Delete user (userList): data table
	deleteUser: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=user&action=delete_user', {user_id:payload.id} )
		.then(
			res => {
				if(!res.data.success){				
					general_mutations.hideModalWorking(general_state.state);
					var error = res.data.error ? res.data.error : "Napaka pri brisanju uporabnika!";
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
				}
				else{
					commit('deleteUser', payload.id);
					general_mutations.hideModalWorking(general_state.state);
				}
			}
		)
		.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},
	
	// Edit user program access
	editUserPrograms: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		payload.user_id = payload.id;
		axios.post('?module=user&action=edit_user_programs', payload )
		.then(
			res => {
				if(!res.data.success){				
					general_mutations.hideModalWorking(general_state.state);
					var error = res.data.error ? res.data.error : "Napaka pri urejanju uporabnika!";
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
				}
				else{
					commit('editUserPrograms', payload);
					general_mutations.hideModalWorking(general_state.state);
				}
			}
		)
		.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

	// Edit user departments access
	editUserDepartments: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		payload.user_id = payload.id;
		axios.post('?module=user&action=edit_user_oddelki', payload )
		.then(
			res => {
				if(!res.data.success){				
					general_mutations.hideModalWorking(general_state.state);
					var error = res.data.error ? res.data.error : "Napaka pri urejanju uporabnika!";
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
				}
				else{
					commit('editUserDepartments', payload);
					general_mutations.hideModalWorking(general_state.state);
				}
			}
		)
		.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

	// Edit  users  (userList): data table
	editUser: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		payload.user_id = payload.id;
		axios.post('?module=user&action=edit_user', payload)
		.then(
			res => {
				if(!res.data.success){				
					general_mutations.hideModalWorking(general_state.state);
					var error = res.data.error ? res.data.error : "Napaka pri urejanju uporabnika!";
					if(error.includes("Duplicate") && error.includes("email"))
						error = "Uporabnik s tem elektronskim naslovom že obstaja!"
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
				}
				else{
					commit('editUser', payload);
					general_mutations.hideModalWorking(general_state.state);
					if(payload.show_message)
						general_mutations.showModal(general_state.state, {component: 'ModalWarning', data: {title: "Uporabnik", description:"Podatki uporabnika uspešno posodobljeni"}})
				}
			}
		)
		.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

	// Set password of users (new user or lost password)
	setPassword: ({commit, dispatch}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=user&action=set_password', payload)
		.then(
			res => {
				if(!res.data.success){				
					general_mutations.hideModalWorking(general_state.state);
					var error = res.data.error ? res.data.error : "Napaka pri natavitvi gesla!";
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
				}
				else{
					general_mutations.hideModalWorking(general_state.state);

					//autologin
					dispatch('loginUser', payload);
				}
			}
		)
		.catch(error => {
				general_mutations.hideModalWorking(general_state.state);
				general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
			});
	},

	// Check if user did not yet set the password (does the hash still exist?)
	checkHash: ({commit, dispatch}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=user&action=check_hash', payload)
		.then(
			res => {
				general_mutations.hideModalWorking(general_state.state);

				if(!res.data.success){				
					router.push('/login-password/');
				}
			}
		)
		.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});
	},

	// Get faq text
	getFaq: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=faq&action=get_faq&tab='+payload.tab, payload)
		.then(
			res => {
				if(!res.data){				
					general_mutations.hideModalWorking(general_state.state);
					var error = res.data.error ? res.data.error : "Napaka";
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
				}
				else{
					commit('getFaq', res.data.data);
					general_mutations.hideModalWorking(general_state.state);
				}
			}
		)
		.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

	// Save faq text
	sendFaq: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=faq&action=save_faq&tab='+payload.tab, payload)
		.then(
			res => {
				if(!res.data){				
					var error = res.data.error ? res.data.error : "Napaka";
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
				}
				else{
					commit('getFaq', payload.text);
					general_mutations.hideModalWorking(general_state.state);
				}
			}
		)
		.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

	// Get notices
	getNotices: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=faq&action=get_notices&tab='+payload.tab, payload)
		.then(
			res => {
				if(!res.data){				
					general_mutations.hideModalWorking(general_state.state);
					var error = res.data.error ? res.data.error : "Napaka";
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
				}
				else{
					commit('getNotices', res.data);
					general_mutations.hideModalWorking(general_state.state);
				}
			}
		)
		.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

	// Add notice
	addNotice: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.get('?module=faq&action=add_notice&tab='+payload.tab)
		.then(
			res => {
				if(!res.data){				
					var error = res.data.error ? res.data.error : "Napaka";
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
				}
				else{
					payload.id = res.data.notice_id;
					commit('addNotice', payload);
					general_mutations.hideModalWorking(general_state.state);
				}
			}
		)
		.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

	// Save notice
	editNotice: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=faq&action=edit_notice', payload)
		.then(
			res => {
				if(!res.data){				
					var error = res.data.error ? res.data.error : "Napaka";
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
				}
				else{
					commit('editNotice', payload);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalWarning', data: {title: "Obvestilo", description:"Obvestilo je uspešno shranjeno"}})
				}
			}
		)
		.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

	deleteNotice: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=faq&action=delete_notice', payload)
		.then(
			res => {
				if(!res.data){				
					var error = res.data.error ? res.data.error : "Napaka";
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
				}
				else{
					commit('deleteNotice', payload);
					general_mutations.hideModalWorking(general_state.state);
				}
			}
		)
		.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

    // Action to password recovery
	passRecovery: ({commit}, payload) => {
        general_mutations.showModalWorking(general_state.state);
		axios.post('?module=other&action=pass_recovery', payload)
		.then(
			res => {
                general_mutations.hideModalWorking(general_state.state);
				if(!res.data){				
					var error = res.data.error ? res.data.error : "Napaka";
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
				}
				else if(res.data.success){
                    general_mutations.showModal(general_state.state, {component: 'ModalWarning', 
                    data: {title:"Geslo spremenjeno", description:"Sporočilo z navodili za aktivacijo novega gesla vam je bilo poslano na elektronsko pošto: " + payload.email}})
				}
                else{
                    general_mutations.showModal(general_state.state, {component: 'ModalWarning', 
                    data: {title:"Napaka", description: res.data.error}})
                }
			}
		)
		.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

	// Send test mail
	sendTestEmail: ({commit}, payload) => {
        general_mutations.showModalWorking(general_state.state);
		axios.post('?module=user&action=mail_test', payload)
		.then(
			res => {
				general_mutations.hideModalWorking(general_state.state);
				if(!res.data){				
					general_mutations.showModal(general_state.state, {component: 'ModalWarning', 
                    data: {title:"Napaka", description: "Pri pošiljanju obvestila na vaš elektronski nalsov je prišlo do napake"}})
					console.log(res.data);
				}
				else{
					general_mutations.showModal(general_state.state, {component: 'ModalWarning', 
                    data: {title:"Testno obvestilo", description: "Obvestilo je uspešno poslano na vaš elektronksi naslov"}})
				}
			}
		)
		.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

	// send development test email
	sendTest: ({commit}, payload) => {
		axios.get('?module=user&action=mail_test_dev')
		.then(
			res => {
				console.log(res);
			}
		)
		.catch(error => {
					console.log(error);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});			
	},

	// Send group mail
	sendGroupEmail: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);

		payload.year = general_state.state.active_year_date.active_year;
		axios.post('?module=user&action=mail_group', payload)
		.then(
			res => {
				general_mutations.hideModalWorking(general_state.state);
				if(!res.data.success){	
					if(res.data.error == 'no_recipients'){
						general_mutations.showModal(general_state.state, {component: 'ModalWarning', 
						data: {title:"Opozorilo", description: "Za dane nastavitve ni obstoječih prejemnikov obvestila."}})
					}		
					else{
						general_mutations.showModal(general_state.state, {component: 'ModalWarning', 
						data: {title:"Napaka", description: "Pri pošiljanju obvestila je prišlo do napake. Preverite svoj poštni predal - lahko, da nekaterim uporabnikom ni bila dostavljeno obvestilo."}})
					}
				}
				else{
					general_mutations.showModal(general_state.state, {component: 'ModalWarning', 
					data: {title:"Pošiljanje bvestila", description: "Obvestilo je uspešno poslano " + res.data.data.recipients_count + " uporabnikom"}})
				}
			}
		)
		.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

	
	// Login user with salm
	loginUserSaml: ({commit, dispatch}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=user&action=login_user_saml', payload)
			.then(
				res => {		
					// Login succesful
					if(res.data.success === true){
						general_mutations.hideModalWorking(general_state.state);

						// Commit changes to store (user, faculty, token...)
						dispatch('getActiveYear');
						commit('setAuthData', res);
						commit('setToken', res.data.token);

						// Save token, faculty_id and user id to local storage
						localStorage.setItem('token', res.data.token);
						//localStorage.setItem('user_id', res.data.user.id);
						localStorage.setItem('faculty', JSON.stringify(res.data.faculty));
						localStorage.setItem('year', general_state.state.active_year_date.active_year);
						let expirationDate = new Date(new Date().getTime() + general_state.state.expirationTime).getTime();
						localStorage.setItem('expirationDate', expirationDate);

						//Redirect based on access
						if(general_state.state.user.access_sections == 1){
							router.replace('/podrocja/');
						}
						else if(general_state.state.user.access_work_plan == 1){
							router.replace('/program-dela/');
						}
						else if(general_state.state.user.access_evalvation == 1){
							router.replace('/samoevalvacija/programi/');
						}
					}
					// Login failed
					else{
						general_mutations.hideModalWorking(general_state.state);
						if(res.data.error == 'Uporabnik ni aktiven. Prosimo, kontaktirajte upravitelja na članici.'){
							general_mutations.showModal(general_state.state, {component: 'ModalError', data: res.data.error});
						}
						else{
							console.log(res.data.error);
							general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka pri prijavi - za podrobnosti se obrnite na administratorja"});
						}
					}
				}
			)
			.catch(error => console.log(error))
	},

	//Change user type for admin for testing
	changeUserTypeTest: ({commit, dispatch}, payload) => {
		commit('changeUserTypeTest', payload);
	},
};


export default{
	state,
	getters,
	actions,
	mutations	
}