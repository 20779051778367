import axios from 'axios';
import Vue from 'vue';
import * as general_mutations from '../mutations';
import * as general_state from '../store';
import * as evalvation_questionnaire from './evalvation_questionnaire';

const state = {
    
	// Attachments in section list
	faculty_attachments: {},
	
	// Attachments for section
	section_attachments: {},

	// Attachments for question
	question_attachments: {},
};


const getters = {

	// Return Attachments in section list
	faculty_attachments: store => {
		return state.faculty_attachments;
	},

	// Return Attachments for section
	section_attachments: store => {
		return state.section_attachments;
	},

	// Return Attachments for question
	question_attachments: store => {
		return state.question_attachments;
	},
};


const mutations = {	

    // Set Attachments in section list
	set_faculty_attachments: (state, payload) => {
		state.faculty_attachments = payload;
    },
    
	// Set Attachments for section
    set_section_attachments: (state, payload) => {
		state.section_attachments = payload;
    },
	
	// Set Attachments for question
	set_question_attachments: (state, payload) => {
		state.question_attachments = payload;
	},
	
    removeFacultyAttachment: (state, id) => {
        let temp = state.faculty_attachments;
        delete temp[id];
        state.faculty_attachments = {};
        state.faculty_attachments = temp;
    },

    removeSectionAttachment: (state, id) => {
        let temp = state.section_attachments;
        delete temp[id];
        state.section_attachments = {};
        state.section_attachments = temp;
	},

	removeQuestionAttachment: (state, payload) => {
		evalvation_questionnaire.default.state.questionnaire_data[payload.question_id].attachment_count--;

        let temp = state.question_attachments;
        delete temp[payload.id];
        state.question_attachments = {};
        state.question_attachments = temp;
	},
	
	addFacultyAttachment: (state, payload) => {
		let temp = state.faculty_attachments;

		//needed so it wont fore into array
		if(Object.keys(temp).length == 0)
			temp = {};

        temp[payload.id] = payload;
        state.faculty_attachments = {};
        state.faculty_attachments = temp;
	},

	addSectionAttachment: (state, payload) => {
		let temp = state.section_attachments;

		//needed so it wont fore into array
		if(Object.keys(temp).length == 0)
			temp = {};

        temp[payload.id] = payload;
        state.section_attachments = {};
        state.section_attachments = temp;
	},

	addQuestionAttachment: (state, payload) => {
		if(!evalvation_questionnaire.default.state.questionnaire_data[payload.question_id]){
			Vue.set(evalvation_questionnaire.default.state.questionnaire_data, payload.question_id, {});
		}
		if(!evalvation_questionnaire.default.state.questionnaire_data[payload.question_id].answer){
			Vue.set(evalvation_questionnaire.default.state.questionnaire_data[payload.question_id], 'answer', '');
		}
		if(!evalvation_questionnaire.default.state.questionnaire_data[payload.question_id].attachment_count){
			Vue.set(evalvation_questionnaire.default.state.questionnaire_data[payload.question_id], 'attachment_count', 0);
		}
		evalvation_questionnaire.default.state.questionnaire_data[payload.question_id].attachment_count++;

		let temp = state.question_attachments;
		//needed so it wont fore into array
		if(Object.keys(temp).length == 0)
			temp = {};

		temp[payload.id] = payload;
        state.question_attachments = {};
		state.question_attachments = temp;
	},
};


const actions = {
    
	// Get attachments for faculty
	getFacultyAttachments: ({commit}) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=upload&action=get_faculty_uploads&year='+general_state.state.year+'&faculty='+general_state.state.faculty.id)
		.then(
			res => {
				if(!res.data){				
					var error = res.data.error ? res.data.error : "Napaka";
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
				}
				else{
					commit('set_faculty_attachments', res.data);
					general_mutations.hideModalWorking(general_state.state);
				}
			}
		)
		.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
    },
    
    // Upload attachment for faculty
	uploadFacultyAttachment: ({commit}, payload) => {
        general_mutations.showModalWorking(general_state.state);

        let formData = new FormData();
        formData.append('file', payload.file);
        formData.set('name', payload.file_name);

		axios.post('?module=upload&action=upload_faculty_file&year='+general_state.state.year+'&faculty='+general_state.state.faculty.id+'&tab='+payload.tab, formData)
			.then(
				res => {
					if(!res.data.success){				
                        var error = res.data.error ? res.data.error : formData;
                        general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
					}
					else{
						//create upload time string
						let now = new Date();
						let stringNow = now.getFullYear() +'-'+ (now.getMonth()+1) +'-'+ now.getDate() +' '+ now.getHours() +':'+ now.getMinutes() + ':' + now.getSeconds();
						
						//create new upload object
						let new_upload = {id: res.data.upload_id, faculty_id: general_state.state.faculty.id, year: general_state.state.year, 
							name: payload.file_name, url: res.data.upload_url, file_name: payload.file.name, upload_time: stringNow, tab: payload.tab};

						commit('addFacultyAttachment', new_upload);
                        general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
            .catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});	
    },
    
    // Delete attachment
	deleteAttachment: ({commit}, payload) => {
        general_mutations.showModalWorking(general_state.state);
		axios.post('?module=upload&action=delete_upload&upload_id='+payload.id+'&type='+payload.type, payload)
			.then(
				res => {
					if(!res.data.success){				
                        var error = res.data.error ? res.data.error : formData;
                        general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
					}
					else{
                        if(payload.type == 1)
                            commit('removeFacultyAttachment', payload.id);
                        else if(payload.type == 2)
							commit('removeSectionAttachment', payload.id);
						else if(payload.type == 4)
                            commit('removeQuestionAttachment', {id:payload.id, question_id:payload.question_id});

                        general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
            .catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});	
    },
    
    // Get attachments for section
	getSectionAttachments: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=upload&action=get_section_uploads&section_id='+payload.section_id+'&faculty='+general_state.state.faculty.id)
		.then(
			res => {
				if(!res.data){				
					var error = res.data.error ? res.data.error : "Napaka";
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
				}
				else{
					commit('set_section_attachments', res.data);
					general_mutations.hideModalWorking(general_state.state);
				}
			}
		)
		.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
    },
    

    // Upload attachment for section
	uploadSectionAttachment: ({commit}, payload) => {
        general_mutations.showModalWorking(general_state.state);

        let formData = new FormData();
        formData.append('file', payload.file);
        formData.set('name', payload.file_name);

		axios.post('?module=upload&action=upload_sections_file&section_id='+payload.section_id+'&faculty='+general_state.state.faculty.id, formData)
			.then(
				res => {
					if(!res.data.success){				
                        var error = res.data.error ? res.data.error : res.data;
                        general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
					}
					else{
						//create upload time string
						let now = new Date();
						let stringNow = now.getFullYear() +'-'+ (now.getMonth()+1) +'-'+ now.getDate() +' '+ now.getHours() +':'+ now.getMinutes() + ':' + now.getSeconds();
						
						//create new upload object
						let new_upload = {id: res.data.upload_id, faculty_id: general_state.state.faculty.id, year: general_state.state.year, 
							name: payload.file_name, url: res.data.upload_url, file_name: payload.file.name, upload_time: stringNow};
							
						commit('addSectionAttachment', new_upload);

                        general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
            .catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});	
	},

	// Get attachments for question
	getQuestionAttachments: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);
		axios.post('?module=upload&action=get_evalvation_question_uploads&question_id='+payload.question_id+'&program_id='+payload.program_id)
		.then(
			res => {
				if(!res.data){	
					var error = res.data.error ? res.data.error : "Napaka";
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
				}
				else{
					commit('set_question_attachments', res.data);
					general_mutations.hideModalWorking(general_state.state);
				}
			}
		)
		.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});		
	},

	// Upload attachment for section
	uploadQuestionAttachment: ({commit}, payload) => {
		general_mutations.showModalWorking(general_state.state);

		let formData = new FormData();
		formData.append('file', payload.file);
		formData.set('name', payload.file_name);
		formData.set('additional_programs', JSON.stringify(payload.additional_programs));

		axios.post('?module=upload&action=upload_evalvation_question_file&question_id='+payload.question_id+'&program_id='+payload.program_id, formData)
			.then(
				res => {
					if(!res.data.success){				
						var error = res.data.error ? res.data.error : res.data;
						general_mutations.hideModalWorking(general_state.state);
						general_mutations.showModal(general_state.state, {component: 'ModalError', data: error})
					}
					else{
						//create upload time string
						let now = new Date();
						let stringNow = now.getFullYear() +'-'+ (now.getMonth()+1) +'-'+ now.getDate() +' '+ now.getHours() +':'+ now.getMinutes() + ':' + now.getSeconds();
						
						//create new upload object
						let new_upload = {id: res.data.upload_id, faculty_id: general_state.state.faculty.id, year: general_state.state.year, 
							name: payload.file_name, url: res.data.upload_url, file_name: payload.file.name, upload_time: stringNow, question_id: payload.question_id};

						commit('addQuestionAttachment', new_upload);

						general_mutations.hideModalWorking(general_state.state);
					}
				}
			)
			.catch(error => {
					console.log(error);
					general_mutations.hideModalWorking(general_state.state);
					general_mutations.showModal(general_state.state, {component: 'ModalError', data: "Napaka v povezavi. Prosimo, poskusite ponovno."});
				});	
	},

};


export default{
	state,
	getters,
	actions,
	mutations	
}